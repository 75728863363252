import { render, staticRenderFns } from "./CreateAlert.vue?vue&type=template&id=26fce522&scoped=true"
import script from "./CreateAlert.vue?vue&type=script&lang=ts"
export * from "./CreateAlert.vue?vue&type=script&lang=ts"
import style0 from "./CreateAlert.vue?vue&type=style&index=0&id=26fce522&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "26fce522",
  null
  
)

export default component.exports