
import { Component, Prop, Vue } from 'vue-property-decorator';
import { CreateUserTarget } from '@/models/userTarget';
import TargetsMetricsSelect from '../inputs/TargetsMetricSelect.vue';
import { userTargetsService } from '@/services/userTargets.service';
import { Tenant } from '@/models/tenant';
import { ConsoleEnum } from '@/models/enums/ConsoleEnum';
import { TargetsMetric } from '@/models/enums/TargetsMetric';
import { isEmpty } from '@/utils/misc';

@Component({
  computed: {
    TargetsMetric: () => TargetsMetric,
  },
  components: {
    TargetsMetricsSelect,
  },
})
export default class CreateTarget extends Vue {
  @Prop({ required: true })
  private console!: ConsoleEnum;

  @Prop({ required: true })
  private tenant!: Tenant;

  private target = CreateUserTarget.GetEmpty();
  private targetValue1: number | null = null;
  private targetValue2: number | null = null;

  private createNewTargetButton = true;
  private validInputs = [true, true];

  private createNewTarget() {
    this.$spinner.showSpinner();
    const createdTarget = new CreateUserTarget(
      this.tenant.id,
      this.target.targetMetric,
      this.targetValue1,
      this.targetValue2,
    );
    userTargetsService
      .create(createdTarget)
      .then(() => {
        this.$emit('created');
        this.cleanup();
      })
      .finally(() => this.$spinner.removeSpinner());
  }

  private cleanup() {
    this.target = CreateUserTarget.GetEmpty();
    this.targetValue1 = null;
    this.targetValue2 = null;
    this.createNewTargetButton = true;
  }

  private isFormValid() {
    if (!this.target || !this.$refs.form) {
      return false;
    }

    const form = this.$refs.form as any;
    if (
      this.target.targetMetric === TargetsMetric.LaserStarveBlock ||
      this.target.targetMetric === TargetsMetric.TubeStarveBlock
    ) {
      return (
        !!this.targetValue1 &&
        !!this.targetValue2 &&
        form.checkValidity() &&
        this.validInputs.every(Boolean)
      );
    } else {
      return !!this.targetValue1 && form.checkValidity() && this.validInputs[0];
    }
  }

  private checkValidity(payload: string, index: number) {
    if (isEmpty(payload) || !payload.trim()) {
      this.validInputs[index] = false;
    } else {
      const targetValue: number = +payload;
      this.validInputs[index] =
        this.targetValueWithinBounds(targetValue, this.target.targetMetric) && !isNaN(targetValue);
    }
  }

  private targetValueWithinBounds(targetValue: number, targetsMetric: TargetsMetric): boolean {
    if (targetsMetric === TargetsMetric.LaserOee || targetsMetric === TargetsMetric.TubeOee) {
      return !(targetValue < 0 || targetValue > 100);
    } else {
      return targetValue >= 0;
    }
  }

  private showCreateNewTarget() {
    this.createNewTargetButton = false;
  }

  private cancelNewTarget() {
    this.cleanup();
  }

  private get numberOfTargetValues(): number {
    return userTargetsService.store.getMetricNumberOfTargetValues(this.target.targetMetric);
  }
}
