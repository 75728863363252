
import { Component, Vue } from 'vue-property-decorator';
import { Alert } from '@/models/alert';
import TableWithActions, {
  truncatedValueColumnEllipsisTitle,
} from '@/components/tableWithActions/TableWithActions.vue';
import CreateCareAlert from '@/components/alerts/CreateCareAlert.vue';
import TableActionButton from '@/components/tableWithActions/TableActionButton.vue';
import EditCareAlert from '@/components/alerts/EditCareAlert.vue';
import { alertsService } from '@/services/alerts.service';
import { FilterTimeAxisSpanEnum } from '@/models/enums/FilterTimeAxisSpanEnum';
import { isNil } from '@/utils/misc';
import i18n from '@/i18n';
import { listToCommaSeparatedString } from '@/utils/array';
import { translateDateGrouping } from '@/utils/dates';
import {
  matchesDateGrouping,
  matchesMetric,
  matchesOperator,
  matchesThreshold,
  matchesVariable,
  formatWithUnit,
  translateKpi,
  translateOperator,
  translateVariable,
} from '@/utils/alerts';

@Component({
  methods: {
    matchesDateGrouping,
    matchesMetric,
    matchesOperator,
    matchesVariable,
    matchesThreshold,
    formatWithUnit,
    listToCommaSeparatedString,
    isNil,
    translateDateGrouping,
    translateKpi,
    translateOperator,
    translateVariable,
    truncatedValueColumnEllipsisTitle,
  },
  computed: {
    FilterTimeAxisSpanEnum: () => FilterTimeAxisSpanEnum,
  },
  components: { TableActionButton, TableWithActions },
})
export default class CareConsoleAlerts extends Vue {
  private alerts: Alert[] = [];

  private created() {
    this.updateAlerts();
  }

  private async updateAlerts() {
    this.alerts = await alertsService.getCareConsoleAlerts();
  }

  private createAlert() {
    this.$buefy.modal.open({
      parent: this,
      component: CreateCareAlert,
      hasModalCard: true,
      trapFocus: true,
      events: {
        save: () => this.updateAlerts(),
      },
    });
  }

  private editAlert(alert: Alert) {
    this.$buefy.modal.open({
      parent: this,
      component: EditCareAlert,
      hasModalCard: true,
      trapFocus: true,
      events: {
        save: () => this.updateAlerts(),
      },
      props: {
        alert: Alert.GetCopy(alert),
      },
    });
  }

  private onDeleteClicked(alert: Alert): void {
    const messageParams = {
      deviceName: alert.deviceName,
      metric: i18n.t(`alert.metric.${alert.metric}`),
    };

    this.$buefy.dialog.confirm({
      title: i18n.t('dialog.confirm_title').toString(),
      message: i18n.t('alert.dialog.delete_message', messageParams).toString(),
      confirmText: i18n.t('dialog.confirm_delete').toString(),
      cancelText: i18n.t(`dialog.confirm_cancel`).toString(),
      type: 'is-danger',
      hasIcon: true,
      onConfirm: () => this.deleteDevice(alert.id!), // TODO use id
    });
  }

  private deleteDevice(alertId: number): void {
    this.$spinner.showSpinner();

    alertsService
      .delete(alertId)
      .then(() => (this.alerts = this.alerts.filter((alert) => alert.id !== alertId)))
      .finally(() => {
        this.$spinner.removeSpinner();
      });
  }
}
