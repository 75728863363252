
import { Component, Vue } from 'vue-property-decorator';
import { tenantsService } from '@/services/tenants.service';
import { Tenant } from '@/models/tenant';
import TimezonesSelect from '../inputs/TimezonesSelect.vue';
import CurrencyCodesSelect from '../inputs/CurrencyCodesSelect.vue';
import MeasurementsSelect from '../inputs/MeasurementsSelect.vue';
import SubsidiariesSelect from '../inputs/SubsidiariesSelect.vue';
import TenantSourcesSelect from '../inputs/TenantSourcesSelect.vue';
import { ValidationObserver, ValidationProvider } from 'vee-validate';
import RequiredFieldsHint from '../forms/RequiredFieldsHint.vue';

@Component({
  components: {
    RequiredFieldsHint,
    'sc-timezones-select': TimezonesSelect,
    'sc-currencycodes-select': CurrencyCodesSelect,
    'sc-measurements-select': MeasurementsSelect,
    'sc-subsidiaries-select': SubsidiariesSelect,
    'sc-tenantsources-select': TenantSourcesSelect,
    ValidationObserver,
    ValidationProvider,
  },
})
export default class CreateTenant extends Vue {
  public tenant = Tenant.GetEmpty();
  public modalVisible = false;

  private get validationRules(): any {
    return {
      name: {
        required: true,
        max: 256,
      },
      subsidiaryId: 'required',
      email: {
        email: true,
        max: 256,
      },
      foreName: {
        max: 256,
      },
      lastName: {
        max: 256,
      },
      sapNumber: {
        required: true,
        max: 256,
      },
      currencyCode: 'required',
      measurement: 'required',
      timeZone: 'required',
      bystronicOrderNumber: {
        required: this.tenant.hasDatahub || this.tenant.hasSmbs,
        max: 1024,
      },
      bystronicOrderPerson: {
        required: this.tenant.hasDatahub || this.tenant.hasSmbs,
        max: 1024,
      },
      customerOrderNumber: {
        max: 1024,
      },
      customerOrderPerson: {
        max: 1024,
      },
    };
  }

  public openModal(): void {
    this.tenant = Tenant.GetEmpty();
    this.modalVisible = true;
  }

  public async onSubmit() {
    this.$spinner.showSpinner();
    tenantsService
      .create(this.tenant)
      .then(() => {
        this.closeModal();
      })
      .finally(() => this.$spinner.removeSpinner());
  }

  public closeModal() {
    this.modalVisible = false;
    this.$emit('onClose');
  }
}
