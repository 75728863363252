
import { Component, Vue } from 'vue-property-decorator';
import { Tenant } from '@/models/tenant';
import { tenantsService } from '@/services/tenants.service';
import { Routes } from '@/router/routes';
import CreateTenant from '../../components/tenants/CreateTenant.vue';
import { User } from '@/models/user';
import { UserType } from '@/models/userType';
import { hasCurrentUserPermission } from '@/utils/permissionUtils';
import { PersistentGetters } from '@/store/persistent/enums';
import moment from 'moment';
import TableActionButton from '@/components/tableWithActions/TableActionButton.vue';
import TableWithActions from '@/components/tableWithActions/TableWithActions.vue';
import { usersService } from '@/services/users.service';
import { formatDateWithTime } from '../../utils/table';

@Component({
  methods: { formatDateWithTime },
  components: {
    'sc-create-tenant': CreateTenant,
    TableActionButton,
    TableWithActions,
  },
})
export default class Customers extends Vue {
  private tenants: Tenant[] = [];

  private async mounted() {
    await this.getTenants();
  }

  private confirmDelete(tenant: Tenant): void {
    this.$buefy.dialog.confirm({
      title: this.$i18n.t('dialog.confirm_delete_title').toString(),
      message: this.$i18n
        .t('confirmation.message', {
          action: this.$i18n.t('action.delete').toString().toLowerCase(),
          entity: this.$i18n.t('tenant.name').toString().toLowerCase(),
          name: tenant.name,
        })
        .toString(),
      confirmText: this.$i18n.t('dialog.confirm_delete').toString(),
      cancelText: this.$i18n.t(`dialog.confirm_cancel`).toString(),
      type: 'is-danger',
      hasIcon: true,
      onConfirm: () => this.deleteTenant(tenant.id),
    });
  }

  private async editTenant(tenant: Tenant) {
    await this.$router.push({
      name: Routes.TenantDetail,
      params: { id: tenant.id.toString() },
    });
  }

  private async modalClose() {
    await this.getTenants();
  }

  private async getTenants() {
    this.$spinner.showSpinner();
    this.tenants = await tenantsService.get().finally(() => this.$spinner.removeSpinner());
  }

  private deleteTenant(id: number): void {
    this.$spinner.showSpinner();
    tenantsService
      .delete(id)
      .then(() => {
        this.tenants = this.tenants.filter((x) => x.id !== id);
      })
      .finally(() => {
        this.$spinner.removeSpinner();
      });
  }

  private get canEditTenants(): boolean {
    return hasCurrentUserPermission(UserType.SubsidiaryAdmin);
  }

  private get currentUser(): User {
    return usersService.store.current();
  }

  private filterTenantIdDh(tenant: Tenant, filter: string) {
    return tenant.tenantIdDh.toString().includes(filter.toLowerCase());
  }

  private orderColumnEllipsisTitle(row: any, column: any) {
    if (column.rootClasses.indexOf('order-column') !== -1) {
      return {
        title: row[column.field],
      };
    }
  }
}
