
import { Component, Prop, Vue } from 'vue-property-decorator';
import { locationsService } from '@/services/locations.service';
import { Location } from '@/models/location';
import TenantsSelect from '@/components/inputs/TenantsSelect.vue';
import { hasCurrentUserPermission } from '@/utils/permissionUtils';
import { UserType } from '@/models/userType';

@Component({
  components: {
    TenantsSelect,
  },
})
export default class EditLocation extends Vue {
  @Prop({ required: true })
  private location!: Location;

  private async onSubmit() {
    this.$spinner.showSpinner();
    locationsService
      .update(this.location)
      .then(() => this.closeModal())
      .finally(() => this.$spinner.removeSpinner());
  }

  private get canEditLocation(): boolean {
    return hasCurrentUserPermission(UserType.SubsidiaryAdmin);
  }

  private get isFormValid() {
    if (this.location && this.$refs.form) {
      const refForm = this.$refs.form as any;
      return refForm.checkValidity();
    }
    return false;
  }

  private handleChange(event: any): void {
    this.location = { ...this.location, [event.target.name]: event.target.value };
  }

  private closeModal() {
    this.$emit('close');
  }
}
