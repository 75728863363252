// --------------------------------------------------------------------------------
// <copyright file="laserAvailableVsUsedTimelineGenerator.ts" company="Bystronic Laser AG">
//  Copyright (C) Bystronic Laser AG 2021-2024
// </copyright>
// --------------------------------------------------------------------------------

import { ChartGenerator, ProcedureName } from '@/models/Charts/abstract/chartGenerator';
import { FilterTimeSpanEnum } from '@/models/enums/FilterTimeSpanEnum';
import { FilterTimeAxisSpanEnum } from '@/models/enums/FilterTimeAxisSpanEnum';
import i18n from '@/i18n';
import { abbreviateNumber, decimalFormatter, formatPercentage } from '@/utils/number';
import { LaserAvailableVsUsedTimelineData } from './chartsData';
import { GeneratorParams } from './generatorParams';
import { EChartsOption, LineSeriesOption } from 'echarts';
import { XAXisOption } from 'echarts/types/dist/shared';
import { getDateTooltipLabel, isCategoryXAxis } from '@/utils/charts';
import { metricsService } from '@/services/metrics.service';

export const AVAILABLE_SERIES_NAME = 'available';
export const USED_SERIES_NAME = 'used';

export class LaserAvailableVsUsedTimelineGenerator extends ChartGenerator<
  LaserAvailableVsUsedTimelineData[]
> {
  constructor(procedure: ProcedureName, private tenantIdDh: number) {
    super(procedure);
  }

  override getData(
    selectedDevices: string[],
    selectedShifts: number[],
    timeSpan: FilterTimeSpanEnum | [string, string],
    timeAxisSpan: FilterTimeAxisSpanEnum,
  ) {
    const deviceId = selectedDevices[0];
    const dateFrom = (timeSpan as [string, string])?.[0];
    const dateTo = (timeSpan as [string, string])?.[1];

    return metricsService.getSSCMetrics<LaserAvailableVsUsedTimelineData[]>(
      this.procedure,
      this.tenantIdDh,
      deviceId,
      {
        dateFrom,
        dateTo,
        dateGrouping: timeAxisSpan,
      },
      this.controller,
    );
  }

  override updateOptions(
    data: LaserAvailableVsUsedTimelineData[],
    parameters: GeneratorParams = {},
    prevOptions?: EChartsOption,
  ): EChartsOption {
    const isCategoryAxis = isCategoryXAxis(parameters.timeAxisSpan, data.length);

    return {
      legend: {
        icon: 'roundRect',
        type: 'scroll',
        bottom: 0,
        formatter: (name) => i18n.t(`report.${name}`).toString(),
      },
      grid: {
        top: 28,
        left: 10,
        right: 20,
        bottom: 30,
        containLabel: true,
      },
      tooltip: {
        trigger: 'axis',
        confine: true,
        formatter: LaserAvailableVsUsedTimelineGenerator.getTooltipFormatter(
          parameters.timeAxisSpan,
        ),
      },
      xAxis: {
        type: isCategoryAxis ? 'category' : 'time',
        axisLabel: {
          hideOverlap: true,
        },
        data: isCategoryAxis ? data.map((item) => item.bucket) : undefined,
      } as XAXisOption, // type=category doesn't have data property
      yAxis: {
        name: 'kWh',
        axisLabel: {
          formatter: abbreviateNumber,
        },
      },
      series: this.generateSeries(data, isCategoryAxis),
    };
  }

  private generateSeries(
    data: LaserAvailableVsUsedTimelineData[],
    isCategoryAxis: boolean,
  ): LineSeriesOption[] {
    return [
      { name: AVAILABLE_SERIES_NAME, valueKey: 'availablePowerKwh' },
      { name: USED_SERIES_NAME, valueKey: 'usedPowerKwh' },
    ].map((seriesObject) => ({
      name: seriesObject.name,
      type: 'line',
      areaStyle: {},
      emphasis: { focus: 'series' },
      data: this.getSeriesData(
        data,
        seriesObject.valueKey as keyof LaserAvailableVsUsedTimelineData,
        isCategoryAxis,
      ),
    }));
  }

  private getSeriesData(
    data: LaserAvailableVsUsedTimelineData[],
    valueKey: keyof LaserAvailableVsUsedTimelineData,
    isCategoryAxis: boolean,
  ) {
    return isCategoryAxis
      ? data.map((item) => item[valueKey])
      : data.map((item) => [item.bucket, item[valueKey]]);
  }

  private static getTooltipFormatter(dateGrouping = FilterTimeAxisSpanEnum.Hour) {
    return (params: any) =>
      LaserAvailableVsUsedTimelineGenerator.tooltipFormatter(params, dateGrouping);
  }

  private static tooltipFormatter(
    params: any,
    dateGrouping: FilterTimeAxisSpanEnum | undefined,
  ): string {
    let html = `
    <div data-testid="time-title" style="font-size: 15px">
      ${getDateTooltipLabel(params[0].axisValueLabel, dateGrouping)}
    </div>
    `;

    html += '<div style="height: 6px"></div>'; // chart values separator

    const availableParam = LaserAvailableVsUsedTimelineGenerator.getSeriesTooltipParam(
      params,
      AVAILABLE_SERIES_NAME,
    );
    html += LaserAvailableVsUsedTimelineGenerator.getNumericValueTooltipHtml(availableParam);

    const usedParam = LaserAvailableVsUsedTimelineGenerator.getSeriesTooltipParam(
      params,
      USED_SERIES_NAME,
    );
    html += LaserAvailableVsUsedTimelineGenerator.getPercentageValueTooltipHtml(
      usedParam,
      availableParam.value?.[1] ?? availableParam.value,
    );

    return html;
  }

  private static getSeriesTooltipParam(params: any[], seriesName: string): any {
    return params.find((paramItem) => paramItem.seriesName === seriesName);
  }

  private static getNumericValueTooltipHtml(seriesParam: any): string {
    // ['ISO date', value] or value depending on the date grouping
    const value = seriesParam.value?.[1] ?? seriesParam.value;
    const formattedValue = `${i18n.n(value, { maximumFractionDigits: 2 })} ${i18n.t('report.kwh')}`;
    return LaserAvailableVsUsedTimelineGenerator.getValueTooltipHtml(seriesParam, formattedValue);
  }

  private static getPercentageValueTooltipHtml(seriesParam: any, total: number): string {
    const value = seriesParam.value?.[1] ?? seriesParam.value;
    const percentage = total !== 0 ? (value / total) * 100 : 0;
    const valueString = `${decimalFormatter(value)} kWh (${formatPercentage(percentage)})`;

    return LaserAvailableVsUsedTimelineGenerator.getValueTooltipHtml(seriesParam, valueString);
  }

  private static getValueTooltipHtml(seriesParam: any, value: string): string {
    return `
    <div
      data-testid="series-value-${seriesParam.axisIndex}-${seriesParam.seriesName}"
      style="display: flex; flex-direction: row"
    >
      <div style="flex-grow: 1">
        ${seriesParam.marker}
        ${i18n.t(`report.${seriesParam.seriesName}`).toString()}
      </div>
      <div style="font-weight: bold; padding-left: 3rem">${value}</div>
    </div>
    `;
  }
}
