// --------------------------------------------------------------------------------
// <copyright file="materialGroup.ts" company="Bystronic Laser AG">
//  Copyright (C) Bystronic Laser AG 2021-2024
// </copyright>
// --------------------------------------------------------------------------------

import { Material } from './material';

export class MaterialGroup {
  static GetEmpty(): MaterialGroup {
    return new MaterialGroup(0, '', 0, false);
  }
  static GetCopy(original: MaterialGroup): MaterialGroup {
    return new MaterialGroup(original.id, original.name, original.tenantId, original.isUnknown);
  }
  materials?: Material[] = [];
  constructor(
    public id: number,
    public name: string,
    public tenantId: number,
    public isUnknown: boolean,
  ) {}
}
