// --------------------------------------------------------------------------------
// <copyright file="cutCurrentWorkloadTableDataRetriever.ts" company="Bystronic Laser AG">
//  Copyright (C) Bystronic Laser AG 2021-2024
// </copyright>
// --------------------------------------------------------------------------------

import { metricsService } from '@/services/metrics.service';
import { isEmpty } from '@/utils/misc';
import { devicesService } from '@/services/devices.service';
import { DataRetriever, ProcedureName } from '../abstract/chartGenerator';
import { FilterTimeSpanEnum } from '@/models/enums/FilterTimeSpanEnum';
import { CutCurrentWorkloadDataMessages, CutCurrentWorkloadDataPlanInfo } from '../chartsData';
import { Device } from '@/models/device';

export type GroupedCutCurrentWorkloadData = {
  deviceId: string;
  deviceName: string;
  deviceModel: string;
  currentMessages: CutCurrentWorkloadDataMessages[];
  workload: CutCurrentWorkloadDataPlanInfo[];
};

export class CutCurrentWorkloadTableDataRetriever extends DataRetriever<
  GroupedCutCurrentWorkloadData[]
> {
  constructor(procedure: ProcedureName, private tenantIdDh: number) {
    super(procedure);
  }

  override async getData(
    selectedDevices: string[],
    selectedShifts: number[],
    timeSpan: [string, string] | FilterTimeSpanEnum,
  ): Promise<GroupedCutCurrentWorkloadData[]> {
    const deviceIds: string[] = isEmpty(selectedDevices)
      ? devicesService.store.cutDevicesIds()
      : selectedDevices;
    const rawData = await metricsService.getDevicesCurrentWorkload(
      deviceIds,
      this.tenantIdDh,
      this.controller,
    );
    return deviceIds.map((deviceId) => {
      const device = this.getDeviceById(deviceId);
      const workload = rawData?.cuttingPlanInfo.filter((x) => x.deviceId === deviceId) ?? [];
      const currentMessages = rawData?.currentMessages.filter((x) => x.deviceId === deviceId) ?? [];
      return {
        deviceId,
        deviceName: device.name,
        deviceModel: device.deviceBaseModelName,
        workload,
        currentMessages,
      };
    });
  }

  private getDeviceById(deviceId: string): Device {
    return devicesService.store.findByStringId(deviceId)!;
  }
}
