// --------------------------------------------------------------------------------
// <copyright file="cuttingHeadCommons.ts" company="Bystronic Laser AG">
//  Copyright (C) Bystronic Laser AG 2021-2024
// </copyright>
// --------------------------------------------------------------------------------

import { versionIsGreaterThanOrEqualTo } from '@/utils/version';
import { CuttingHeadVersionInformation } from './chartsData';
import { FilterTimeAxisSpanEnum } from '../enums/FilterTimeAxisSpanEnum';
import { isCategoryAxisDateGrouping, isCategoryXAxis } from '@/utils/charts';
import { isNil, valueOrDefault } from '@/utils/misc';
import i18n from '@/i18n';
import { ScatterDataItemOption } from 'echarts/types/src/chart/scatter/ScatterSeries';
import { uniq } from '@/utils/array';

export const CUTTING_HEAD_VERSION_CHANGE_SYMBOL =
  'path://M 26.79,25.50 C 26.79,25.50 37.23,25.50 37.23,25.50 37.23,25.50 37.23,26.86 37.23,26.86 37.23,27.59 36.94,28.28 36.43,28.79 36.43,28.79 32.21,33.03 32.21,33.03 32.10,33.13 31.92,33.13 31.82,33.03 31.82,33.03 27.59,28.80 27.59,28.80 27.08,28.28 26.79,27.59 26.79,26.86 26.79,26.86 26.79,25.50 26.79,25.50 Z M 29.59,35.25 C 29.84,35.50 30.13,35.69 30.44,35.85 30.44,35.85 30.44,39.10 30.44,39.10 30.44,39.97 31.14,40.67 32.01,40.67 32.88,40.67 33.58,39.97 33.58,39.10 33.58,39.10 33.58,35.86 33.58,35.86 33.89,35.70 34.18,35.50 34.43,35.25 34.43,35.25 38.66,31.01 38.66,31.01 39.76,29.90 40.37,28.43 40.37,26.86 40.37,26.86 40.37,14.83 40.37,14.83 40.37,13.96 39.67,13.26 38.80,13.26 37.93,13.26 37.23,13.96 37.23,14.83 37.23,14.83 37.23,22.35 37.23,22.35 37.23,22.35 26.79,22.35 26.79,22.35 26.79,22.35 26.79,14.83 26.79,14.83 26.79,13.96 26.09,13.26 25.22,13.26 24.35,13.26 23.65,13.96 23.65,14.83 23.65,14.83 23.65,26.86 23.65,26.86 23.65,28.43 24.26,29.90 25.37,31.02 25.37,31.02 29.59,35.25 29.59,35.25 Z M 50.30,35.82 C 50.30,35.82 42.13,30.52 42.13,30.52 41.71,31.43 41.14,32.27 40.41,33.00 40.41,33.00 40.32,33.09 40.32,33.09 40.32,33.09 46.56,37.14 46.56,37.14 46.56,37.14 32.20,46.44 32.20,46.44 32.20,46.44 17.51,37.14 17.51,37.14 17.51,37.14 23.78,33.17 23.78,33.17 23.78,33.17 23.61,33.00 23.61,33.00 22.91,32.29 22.35,31.49 21.93,30.62 21.93,30.62 13.73,35.81 13.73,35.81 13.28,36.10 13.00,36.60 13.00,37.14 13.00,37.68 13.28,38.18 13.73,38.46 13.73,38.46 31.37,49.63 31.37,49.63 31.63,49.80 31.92,49.88 32.21,49.88 32.51,49.88 32.81,49.79 33.07,49.62 33.07,49.62 50.30,38.46 50.30,38.46 50.75,38.16 51.02,37.67 51.02,37.14 51.02,36.60 50.75,36.11 50.30,35.82';

export const FIELD_MARKING_HEAD_VERSION_CHANGE: keyof CuttingHeadVersionInformation =
  'cuttingHeadSoftwareVersion';

export const HEAD_VERSION_CHANGE_MARK_VALUE = 0;

export function doesAllDataComeFromNewerCuttingHeadSoftware(data: CuttingHeadVersionInformation[]) {
  const historyVersions = data
    .filter((d) => !!d.cuttingHeadSoftwareVersion)
    .map((d) => d.cuttingHeadSoftwareVersion!);
  return (
    historyVersions.length > 0 &&
    historyVersions.every((d) => versionIsGreaterThanOrEqualTo('4.0', d))
  );
}

export function cuttingHeadDataHasCategoryAxis(
  timeAxisSpan: FilterTimeAxisSpanEnum | undefined,
  data: Array<CuttingHeadVersionInformation & { bucket: string }>,
) {
  return isCategoryXAxis(timeAxisSpan, uniq(data.map((x) => x.bucket)).length);
}

export function doesAnyDataComeFromNewerCuttingHeadSoftware(data: CuttingHeadVersionInformation[]) {
  return data.some(
    (d) =>
      !!d.cuttingHeadSoftwareVersion &&
      versionIsGreaterThanOrEqualTo('4.0', d.cuttingHeadSoftwareVersion),
  );
}

export function getChartTitleTooltipHtml(translationKey: string) {
  return `
    <div class="tooltip-section-title">
      ${i18n.t(`technology_console.widget_info_text.${translationKey}`)}
    </div>
  `;
}

export function isHeadVersionChangeTooltipParamItem(paramItem: any) {
  return paramItem.seriesType === 'scatter';
}

export function getHeadVersionChangeDataHtml(
  paramItem: any,
  data: CuttingHeadVersionInformation[],
  dateGrouping: FilterTimeAxisSpanEnum,
): string {
  let dataItem: CuttingHeadVersionInformation | null = null;
  if (isCategoryAxisDateGrouping(dateGrouping)) {
    dataItem = data[paramItem.dataIndex];

    if (isNil(dataItem.cuttingHeadSoftwareVersion)) {
      return '';
    }
  } else {
    const cuttingHeadVersionChangeData = (data as CuttingHeadVersionInformation[]).filter(
      (dataItem) => !isNil(dataItem.cuttingHeadSoftwareVersion),
    );

    if (cuttingHeadVersionChangeData.length <= paramItem.dataIndex) {
      return '';
    }

    dataItem = cuttingHeadVersionChangeData[paramItem.dataIndex];
  }

  // The cutting head type property is commented out of the tooltip because it is not
  // temporal data in nature, and representing it with temporal data is misleading and confusing.
  // This is a temporary measure, as it is planned to create a history of cutting head types per device.
  return (
    `
    ${getChartTitleTooltipHtml('cutting_head_version_change_data')}
    <!-- <div data-testid="cutting-head-field-entry">
      <span class="cutting-head-field-label">
        ${i18n.t('report.tenant_technology_devices_table.cuttingHeadType_long')}
      </span>:
      <span class="cutting-head-field-value">
        ${valueOrDefault(dataItem ? dataItem.cuttingHeadType : null, '-')}
      </span>
    </div> -->
    <div data-testid="cutting-head-field-entry">
      <span class="cutting-head-field-label">
        ${i18n.t('report.tenant_technology_devices_table.cuttingHeadSoftwareVersion')}
      </span>:
      <span class="cutting-head-field-value">
        ${valueOrDefault(dataItem ? dataItem.cuttingHeadSoftwareVersion : null, '-')}
      </span>
    </div>
    <div data-testid="cutting-head-field-entry">
      <span class="cutting-head-field-label">
        ${i18n.t('report.tenant_technology_devices_table.cuttingHeadSerialNumber')}
      </span>:
      <span class="cutting-head-field-value">
        ${valueOrDefault(dataItem ? dataItem.cuttingHeadSerialNumber : null, '-')}
      </span>
    </div>
    <div data-testid="cutting-head-field-entry">
      <span class="cutting-head-field-label">
        ${i18n.t('report.tenant_technology_devices_table.cuttingHeadBaseProductNumber')}
      </span>:
      <span class="cutting-head-field-value">
        ${valueOrDefault(dataItem ? dataItem.cuttingHeadBaseProductNumber : null, '-')}
      </span>
    </div>
  ` +
    (dataItem &&
    !isNil(dataItem.cuttingHeadVersionChangesCount) &&
    dataItem.cuttingHeadVersionChangesCount > 1
      ? `
        <div data-testid="cutting-head-field-entry">
          <span class="cutting-head-field-label">
            ${i18n.t('report.cutting_head_version_changes_count')}
          </span>:
          <span class="cutting-head-field-value">
            ${dataItem.cuttingHeadVersionChangesCount}
          </span>
        </div>
        `
      : '')
  );
}

export function getHeadVersionChangeSeriesData(
  data: (CuttingHeadVersionInformation & { bucket: string })[],
  isCategoryAxis: boolean,
): ScatterDataItemOption[] {
  // We don't want to show any specific value, just show a mark when it happens
  return (
    isCategoryAxis
      ? data.map((dataItem) =>
          isNil(dataItem[FIELD_MARKING_HEAD_VERSION_CHANGE])
            ? null
            : HEAD_VERSION_CHANGE_MARK_VALUE,
        )
      : data
          .filter((dataItem) => !isNil(dataItem[FIELD_MARKING_HEAD_VERSION_CHANGE]))
          .map((dataItem) => [dataItem.bucket, HEAD_VERSION_CHANGE_MARK_VALUE])
  ) as ScatterDataItemOption[];
}
