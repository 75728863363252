
import { Component, Prop, Vue } from 'vue-property-decorator';
import { User, UserAuthenticationType } from '@/models/user';
import { UserType } from '@/models/userType';
import { Tenant } from '@/models/tenant';
import { usersService } from '@/services/users.service';
import { tenantsService } from '@/services/tenants.service';
import ResetPassword from '@/components/users/ResetPassword.vue';
import { hasCurrentUserPermission } from '@/utils/permissionUtils';
import TableActionButton from '@/components/tableWithActions/TableActionButton.vue';
import TableWithActions from '@/components/tableWithActions/TableWithActions.vue';
import CustomerUserWizard from '@/components/users/wizards/CustomerUserWizard.vue';
import i18n from '@/i18n';

@Component({
  components: {
    TableWithActions,
    TableActionButton,
  },
})
export default class CustomerUsers extends Vue {
  @Prop({ required: true, default: Tenant.GetEmpty() })
  private customer!: Tenant;

  private users: User[] = [];

  private async created() {
    await this.getUsers();
  }

  private async getUsers() {
    this.$spinner.showSpinner();
    this.users = await tenantsService
      .getUsers(this.customer.id)
      .finally(() => this.$spinner.removeSpinner());
  }

  private openResetPasswordForm(user: User) {
    this.$buefy.modal.open({
      parent: this,
      component: ResetPassword,
      hasModalCard: true,
      trapFocus: true,
      props: {
        id: user.id,
        email: user.email,
      },
    });
  }

  private async onFormModalClosed() {
    if (this.canCreateOrEditCustomerUsers) {
      await this.getUsers();
    }
  }

  private get canCreateOrEditCustomerUsers(): boolean {
    return this.canCreateCustomerUsers || this.canEditCustomerUsers;
  }

  private confirmDelete(id: number, name: string) {
    this.$buefy.dialog.confirm({
      title: this.$i18n.t('dialog.confirm_delete_title').toString(),
      message: this.$i18n
        .t('confirmation.message', {
          action: this.$i18n.t('action.delete').toString().toLowerCase(),
          entity: this.$i18n.t('user.name').toString().toLowerCase(),
          name,
        })
        .toString(),
      confirmText: this.$i18n.t('dialog.confirm_delete').toString(),
      cancelText: this.$i18n.t(`dialog.confirm_cancel`).toString(),
      type: 'is-danger',
      hasIcon: true,
      onConfirm: () => this.deleteUser(id),
    });
  }

  private deleteUser(id: number) {
    this.$spinner.showSpinner();
    usersService
      .delete(id)
      .then(() => {
        this.users = this.users.filter((x) => x.id !== id);
      })
      .finally(() => {
        this.$spinner.removeSpinner();
      });
  }

  private get currentUser(): User {
    return usersService.store.current();
  }

  private get canEditCustomerUsers(): boolean {
    return hasCurrentUserPermission(UserType.CustomerAdmin);
  }

  private get canCreateCustomerUsers(): boolean {
    return hasCurrentUserPermission(UserType.CustomerAdmin);
  }

  private get canResetPasswords(): boolean {
    return hasCurrentUserPermission(UserType.SubsidiaryAdmin);
  }

  private canResetPasswordOn(user: User): boolean {
    return (
      (this.canResetPasswords || user.id === this.currentUser.id) &&
      user.authenticationType === UserAuthenticationType.Local
    );
  }

  private canEdit(editingUser: User): boolean {
    if (this.currentUser.equals(editingUser)) {
      return true;
    }

    if (!this.canEditCustomerUsers) {
      return false;
    }

    if (this.currentUser.isCustomerAdmin && editingUser.isCustomerAdmin) {
      return false;
    }

    return true;
  }

  private openCustomerWizard(user: User | null = null) {
    this.$buefy.modal.open({
      parent: this,
      component: CustomerUserWizard,
      hasModalCard: true,
      trapFocus: true,
      canCancel: ['x'],
      props: {
        user,
        customer: this.customer,
      },
      events: {
        close: () => this.onFormModalClosed(),
      },
    });
  }
}
