
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import TableActions from '@/components/tableWithActions/TableActions.vue';
import SearchBox from '@/components/tableWithActions/SearchBox.vue';
import { matchIgnoringCase } from '@/utils/table';
import { getFieldPathValue, isNil } from '@/utils/misc';
import ControlledSidebar from '@/components/ControlledSidebar.vue';
import { FilterMatcher } from '@/views/careConsole/commons/matchers';
import { SortOrder } from '@/models/enums/SortOrder';

type PaginationPosition = 'top' | 'bottom';
type SortIconSize = 'is-small' | 'is-medium' | 'is-large' | '';

@Component({
  components: {
    ControlledSidebar,
    SearchBox,
    TableActions,
  },
})
export default class TableWithActions2 extends Vue {
  @Prop({ required: true })
  private data!: any[];

  @Prop({ default: true, type: Boolean })
  private paginated!: boolean;

  @Prop({ default: 10 })
  private rowsPerPage!: number;

  @Prop({ default: false, type: Boolean })
  private paginationSimple!: boolean;

  @Prop({ default: 'bottom' })
  private paginationPosition!: PaginationPosition;

  @Prop({ default: SortOrder.Ascending })
  private defaultSortDirection!: SortOrder;

  @Prop({ default: 'arrow-up' })
  private sortIcon!: string;

  @Prop({ default: 'is-small' })
  private sortIconSize!: SortIconSize;

  @Prop({ default: false, type: Boolean })
  private checkable!: boolean;

  @Prop()
  private checkedRows?: any[];

  @Prop()
  private filterMatchers?: Record<string, FilterMatcher>;

  @Prop()
  private defaultSort?: string | string[];

  @Prop()
  private titleField?: string;

  @Prop()
  private titleLabel?: string;

  @Prop({ default: false, type: Boolean })
  private loading!: boolean;

  @Prop({ default: false, type: Boolean })
  private hoverable!: boolean;

  @Prop({ default: false, type: Boolean })
  private detailed!: boolean;

  @Prop()
  private detailKey?: string;

  @Prop({ default: false, type: Boolean })
  private customDetailRow!: boolean;

  @Prop({ default: true, type: Boolean })
  private searchable!: boolean;

  private filteredData: any[] = [];
  private searchText = '';
  private columnSearchMatchers: SearchMatcher[] = [];
  private areFiltersOpen = false;
  private isRowMenuOpen = false;
  private areFiltersApplying = false;
  private isSearchBoxActive = false;
  private filterValues: Record<string, any> = {};

  private mounted() {
    this.columnSearchMatchers = this.getColumnSearchMatchers();
  }

  @Watch('data', { immediate: true })
  private onDataChanged() {
    if (this.areFiltersApplying) {
      // If loading the page with filters defined in the URL
      this.filteredData = this.filterData(this.filterValues);
    } else {
      this.filteredData = this.data;
    }
    this.searchText = '';
  }

  private onSearchTextChanged(searchText: string) {
    this.searchText = searchText;
    this.filteredData = this.searchData(searchText);
  }

  private searchData(searchText: string): any[] {
    return this.data.filter((dataItem) =>
      this.columnSearchMatchers.some((matches) => matches(dataItem, searchText)),
    );
  }

  private onFiltersChanged(filterValues: Record<string, any>, areApplying: boolean) {
    this.filteredData = this.filterData(filterValues);
    this.areFiltersOpen = false;
    this.filterValues = filterValues;
    this.areFiltersApplying = areApplying;
  }

  private filterData(filterValues: Record<string, any>): any[] {
    return this.data.filter((dataItem) =>
      Object.entries(filterValues).every(([filterKey, filterValue]) => {
        const matches = this.filterMatchers![filterKey];
        return matches && matches(dataItem, filterValue);
      }),
    );
  }

  private getColumnSearchMatchers() {
    return (this.$slots.default as any[])
      ?.filter((slot: any) => !isNil(slot.componentOptions))
      .map((slot: any) => slot.componentOptions.propsData)
      .map(
        (slotProps: any) => slotProps.customSearch ?? createDefaultStringMatcher(slotProps.field),
      );
  }

  private onRowClicked(row: any) {
    if (!this.isRowMenuOpen) {
      this.$emit('click', row);
    }
  }

  @Watch('data')
  private resetCheckedRowsCount() {
    this.$emit('update:checked-rows', []);
  }

  /** Prevents the row click to be triggered when the row menu is toggled. */
  private workaroundClickPropagationIssue(isOpen: boolean) {
    if (isOpen) {
      this.isRowMenuOpen = true;
    } else {
      // If we changed isRowMenuOpen instantly, closing the menu by clicking the
      // menu trigger would trigger the row click.
      setTimeout(() => (this.isRowMenuOpen = false));
    }
  }

  private get hasFilters(): boolean {
    return !isNil(this.$scopedSlots.filters);
  }

  private get hasSelectionActions(): boolean {
    return !isNil(this.$scopedSlots['selection-actions']);
  }

  private get hasRowActions(): boolean {
    return !isNil(this.$scopedSlots['row-actions']);
  }

  private get defaultTitleLabel(): string {
    return this.$t('form.name').toString();
  }

  private get hasBottomLeftSlot(): boolean {
    return 'bottom-left' in this.$slots || 'bottom-left' in this.$scopedSlots;
  }

  private get hasDetailsSlot(): boolean {
    return 'detail' in this.$slots || 'detail' in this.$scopedSlots;
  }

  private get showFilters(): boolean {
    return this.hasFilters && !this.isSearchBoxActive;
  }

  private get showToolbarRight(): boolean {
    return this.showFilters || this.hasSelectionActions;
  }

  /**
   * Avoids triggering row clicks by mistake when clicking selection checkboxes.
   *
   * When multiple selection is enabled it's easy to miss the checkbox and end
   * up triggering the row click, which can be very annoying.
   *
   * This is prevented by adding click handlers to all checkbox cells that stop
   * the event propagation so the row click isn't triggered.
   */
  @Watch('filteredData', { immediate: true })
  private avoidCheckboxCellsClickTriggeringRowClick() {
    // Give it time to render the rows for the new data
    this.$nextTick(() =>
      this.$el
        .querySelectorAll('tbody .checkbox-cell')
        .forEach((checkboxCell) =>
          checkboxCell.addEventListener('click', (event) => event.stopPropagation()),
        ),
    );
  }

  private get listeners(): any {
    // We want to propagate, as directly as possible, all events that we are not manually handling.
    // This getter returns all listeners other than those defined below, which are the ones we are
    // handling manually in the component's implementation
    const interceptedEvents = ['click', 'check'];

    return Object.fromEntries(
      Object.entries(this.$listeners).filter(([ev]) => !interceptedEvents.includes(ev)),
    );
  }
}

function createDefaultStringMatcher(fieldPath: string) {
  // FIXME: We should match against the actual table cell content instead of the
  //   object value.
  return (dataItem: any | null, searchText: string) =>
    matchIgnoringCase(getFieldPathValue(dataItem, fieldPath)?.toString(), searchText);
}

export function truncatedValueColumnEllipsisTitle(row: any, column: any) {
  return {
    title: row[column.field],
  };
}

type SearchMatcher = (row: any, searchText: string) => boolean;
