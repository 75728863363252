
import { Component, Vue, Prop, Watch, Ref } from 'vue-property-decorator';
import { RestrictedSubsidiary, subsidiariesService } from '@/services/subsidiaries.service';
import { Subsidiary } from '@/models/subsidiary';
import { ValidationProvider } from 'vee-validate';
import { isNil } from '@/utils/misc';

@Component({
  components: {
    ValidationProvider,
  },
})
export default class SubsidiariesSelect extends Vue {
  @Prop()
  public value?: number;

  @Prop({ default: undefined })
  public rules?: any;

  /** @deprecated Use rules with value 'required' or { required: true } instead. */
  @Prop({ default: false, type: Boolean })
  public required!: boolean;

  @Prop({ default: false, type: Boolean })
  public disabled!: boolean;

  @Prop({ default: true, type: Boolean })
  public showLabel!: boolean;

  @Prop({ default: false, type: Boolean })
  private slim!: boolean;

  @Prop({ default: 'inputs.subsidiaries_select.label' })
  private validationProviderName!: string;

  @Prop({ default: false, type: Boolean })
  private restricted!: boolean;

  @Ref('validation-provider')
  private validationProvider!: InstanceType<typeof ValidationProvider>;

  public items: Array<Subsidiary | RestrictedSubsidiary> = [];

  public async mounted() {
    await this.getItems();
    if (!isNil(this.value)) {
      this.$emit('input:name', this.getSubsidiaryById(this.value)!.name);
    }
  }

  public async getItems() {
    this.$spinner.showSpinner();
    try {
      if (this.restricted) {
        this.items = await subsidiariesService.getRestricted();
      } else {
        this.items = await subsidiariesService.get();
      }
    } finally {
      this.$spinner.removeSpinner();
    }
  }

  private getSubsidiaryById(id: number): Subsidiary | RestrictedSubsidiary | undefined {
    return this.items.find((x) => x.id === id);
  }

  public handleSelect(value: number) {
    const subsidiary = this.getSubsidiaryById(value)!;
    this.$emit('input:name', subsidiary.name);
    this.$emit('input', value);
  }

  private get isRequired(): boolean {
    return this.required || this.rules?.required || this.rules?.includes?.('required');
  }

  @Watch('rules')
  private watchRules() {
    this.$nextTick(() => this.validationProvider.validate());
  }
}
