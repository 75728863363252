// --------------------------------------------------------------------------------
// <copyright file="quotedMaterialsListGenerator.ts" company="Bystronic Laser AG">
//  Copyright (C) Bystronic Laser AG 2021-2024
// </copyright>
// --------------------------------------------------------------------------------

import { FilterTimeAxisSpanEnum } from '../enums/FilterTimeAxisSpanEnum';
import { ChartGenerator, ProcedureName } from './abstract/chartGenerator';
import i18n from '@/i18n';
import { abbreviateNumber, decimalFormatter } from '@/utils/number';
import { QuotedMaterialsListData } from './chartsData';
import { FilterTimeSpanEnum } from '../enums/FilterTimeSpanEnum';
import { EChartsOption } from 'echarts';
import { GeneratorParams } from './generatorParams';
import { YAXisOption } from 'echarts/types/dist/shared';
import { measurementUnit } from '@/utils/measurement';
import { mockQuotesMaterialsListData } from './mockWidgetSelectorData';
import { metricsService } from '@/services/metrics.service';

const OTHERS_NAME = 'OTHERS';

export class QuotedMaterialsListGenerator extends ChartGenerator<QuotedMaterialsListData[]> {
  constructor(procedure: ProcedureName, public tenantIdDh: number) {
    super(procedure);
  }

  override getData(
    selectedDevices: string[],
    selectedShifts: number[],
    timeSpan: FilterTimeSpanEnum | [string, string],
    timeAxisSpan?: FilterTimeAxisSpanEnum,
    params?: { [key: string]: any },
  ) {
    const numberOfMaterials = params?.numberOfMaterials ?? 6;
    const startDate = (timeSpan as [string, string])?.[0];
    const endDate = (timeSpan as [string, string])?.[1];

    return metricsService.getSMBSMetricsBC<QuotedMaterialsListData[]>(
      this.procedure,
      this.tenantIdDh,
      { paramNumber: numberOfMaterials, startDate, endDate },
      this.controller,
    );
  }

  override updateOptions(
    data: QuotedMaterialsListData[],
    parameters: GeneratorParams = {},
    prevOptions?: EChartsOption,
  ): EChartsOption {
    const reorderedMaterials = this.moveOthersItemToTheEnd(data);
    return {
      legend: {
        bottom: 0,
        type: 'scroll',
      },
      dataZoom: [
        {
          show: true,
          type: 'inside',
          realtime: true,
        },
      ],
      tooltip: {
        trigger: 'axis',
        confine: true,
        extraCssText: 'z-index: 1',
        valueFormatter: decimalFormatter,
      },
      grid: {
        containLabel: true,
        left: 8,
        right: 30,
        bottom: 40,
        top: 8,
      },
      xAxis: {
        type: 'value',
        name: measurementUnit(),
        splitLine: {
          show: true,
          lineStyle: {
            color: '#f4f4f4',
            width: 1,
          },
        },
        axisLabel: {
          formatter: abbreviateNumber,
        },
      },
      yAxis: {
        type: 'category',
        data: this.getMaterialNames(reorderedMaterials),
        inverse: true,
        axisLabel: {
          interval: 0,
          overflow: 'truncate' as const,
          width: 95,
        } as YAXisOption, // overflow=truncate not recognized,
      },
      series: this.generateSeries(reorderedMaterials),
    };
  }

  override getMockData(): QuotedMaterialsListData[] | null {
    return mockQuotesMaterialsListData();
  }

  private moveOthersItemToTheEnd(data: QuotedMaterialsListData[]): QuotedMaterialsListData[] {
    const nonOthersItems = data.filter((item) => item.mat_group !== OTHERS_NAME);
    const othersItem = data.find((item) => item.mat_group === OTHERS_NAME);

    if (!othersItem) {
      return nonOthersItems;
    }

    return [...nonOthersItems, othersItem];
  }

  private generateSeries(data: QuotedMaterialsListData[]) {
    return [
      {
        name: i18n.t('report.business_console.materials_converted').toString(),
        type: 'bar' as const,
        stack: 'total',
        data: this.getConvertedValues(data),
      },
      {
        name: i18n.t('report.business_console.materials_non_converted').toString(),
        type: 'bar' as const,
        stack: 'total',
        data: this.getNonConvertedValues(data),
      },
    ];
  }

  private getConvertedValues(data: QuotedMaterialsListData[]): number[] {
    return data.map((materialEntry) => materialEntry.converted_weight);
  }

  private getNonConvertedValues(data: QuotedMaterialsListData[]): number[] {
    return data.map((materialEntry) => materialEntry.weight - materialEntry.converted_weight);
  }

  private getMaterialNames(data: QuotedMaterialsListData[]): string[] {
    return this.removeDuplicates(data.map((item) => this.formatName(item.mat_group)));
  }

  private formatName(name: string) {
    return name === OTHERS_NAME
      ? i18n.t('report.others').toString()
      : name.charAt(0).toUpperCase() + name.slice(1).toLowerCase();
  }

  private removeDuplicates(names: string[]) {
    return [...new Set(names)];
  }
}
