
import { Component, Vue } from 'vue-property-decorator';
import EmailsSelect from '@/components/inputs/EmailsSelect.vue';
import AlertsActionsSelect from '@/components/inputs/AlertsActionsSelect.vue';
import { AlertAction } from '@/models/enums/AlertsEnums';
import { notificationsService } from '@/services/notifications.service';
import { isEmpty } from '@/utils/misc';
import { usersService } from '@/services/users.service';

enum Tab {
  Tenants = 0,
  Subsidiaries = 1,
}

@Component({
  components: {
    'tenants-subsidiaries-select': EmailsSelect,
    'action-select': AlertsActionsSelect,
  },
})
export default class AdminNotification extends Vue {
  private get disabled() {
    return (
      !this.activeForm.messageTxt ||
      isEmpty(this.activeForm.recipients) ||
      (this.activeForm.alertAction === AlertAction.WebAndEmail && !this.activeForm.emailSubject)
    );
  }
  private modalOpen: boolean = false;

  private readonly initialFormValues = {
    recipients: [],
    messageTxt: '',
    sendEmailToAllUsers: true,
    alertAction: AlertAction.Web,
    emailSubject: '',
  };
  private tenantsForm = {
    ...this.initialFormValues,
  };
  private subsidiariesForm = {
    ...this.initialFormValues,
  };
  private activeForm: any = this.tenantsForm;

  private webAndEmailAction: AlertAction = AlertAction.WebAndEmail;
  private activeTab: number = Tab.Tenants;
  private hideTabs = false;

  public openModal() {
    this.modalOpen = true;
    this.hideTabs = !this.canNotifySubsidiaries();
  }

  public closeModal() {
    this.resetModal();
    this.modalOpen = false;
  }

  public canNotifySubsidiaries(): boolean {
    return usersService.store.current().isGlobalAdmin;
  }

  private resetModal() {
    this.activeTab = Tab.Tenants;

    this.tenantsForm = {
      ...this.initialFormValues,
    };

    this.subsidiariesForm = {
      ...this.initialFormValues,
    };

    this.activeForm = this.tenantsForm;
  }

  private onTabChanged() {
    this.activeForm = this.activeTab === Tab.Tenants ? this.tenantsForm : this.subsidiariesForm;
  }

  private onActionInput(action: AlertAction) {
    this.activeForm.alertAction = Number(action);
  }

  private async onSend() {
    if (!this.activeForm.messageTxt) {
      this.$buefy.toast.open(`<b>${this.$i18n.t('message')}:</b> ${this.$i18n.t('text_empty')}`);
      return;
    }
    if (this.activeForm.alertAction === AlertAction.WebAndEmail && !this.activeForm.emailSubject) {
      this.$buefy.toast.open(`<b>${this.$i18n.t('subject')}:</b> ${this.$i18n.t('text_empty')}`);
      return;
    }

    this.$buefy.dialog.confirm({
      title: this.$i18n.t('dialog.confirm_title').toString(),
      message: this.generateSendConfirmationMessage(),
      confirmText: this.$i18n.t('dialog.confirm_send').toString(),
      cancelText: this.$i18n.t(`dialog.confirm_cancel`).toString(),
      type: 'is-primary',
      hasIcon: true,
      onConfirm: () => this.sendNotification(),
    });
  }

  private generateSendConfirmationMessage(): string {
    const target =
      this.activeTab === Tab.Tenants
        ? this.$i18n.t('tenant.plural').toString().toLowerCase()
        : this.$i18n.t('subsidiary.plural').toString().toLowerCase();
    const recipients = this.getSelectedRecipientNames().join(', ');
    const messageParams = {
      target,
      recipients,
    };

    return this.$i18n.t('alert.send_confirm', messageParams).toString();
  }

  private getSelectedRecipientNames(): string[] {
    return this.activeForm.recipients.map((recipient: any) => recipient.name);
  }

  private getSelectedTenantIds(): number[] {
    if (isEmpty(this.tenantsForm.recipients)) {
      return [-1];
    }
    return this.tenantsForm.recipients.map((recipient: any) => recipient.id);
  }

  private getSelectedSubsidiaryIds(): number[] {
    if (isEmpty(this.subsidiariesForm.recipients)) {
      return [-1];
    }
    return this.subsidiariesForm.recipients.map((recipient: any) => recipient.id);
  }

  private async sendNotification() {
    this.$spinner.showSpinner();

    try {
      await notificationsService.createCustomNotification({
        messageTxt: this.activeForm.messageTxt,
        subject: this.activeForm.emailSubject,
        tenantIds: this.getSelectedTenantIds().join(','),
        subsidiaryIds: this.getSelectedSubsidiaryIds().join(','),
        sendToAll: this.activeForm.sendEmailToAllUsers,
        action: this.activeForm.alertAction,
      });
      this.$buefy.toast.open(this.$i18n.t('notification_sent').toString());
    } finally {
      this.$spinner.removeSpinner();
      this.closeModal();
    }
  }
}
