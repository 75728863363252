
import { Component, Prop, Vue } from 'vue-property-decorator';
import { tenantsService } from '@/services/tenants.service';
import { Tenant } from '@/models/tenant';
import { Mode } from '@/store/enums';
import { TenantCutConfig } from '@/models/tenantCutConfig';

@Component({})
export default class CutGasTypeConfig extends Vue {
  @Prop({ required: true, default: Tenant.GetEmpty() })
  private tenant!: Tenant;

  private editTenant = Tenant.GetEmpty();
  private mode = Mode.View;

  private async mounted() {
    this.editTenant = this.tenant;
  }

  private startEdit() {
    this.mode = Mode.Edit;
    Vue.nextTick(() => {
      this.checkHtml5Validity();
    });
  }

  private onFormChanged(): void {
    this.editTenant = Tenant.GetCopy(this.editTenant);
  }

  private get isFormValid() {
    if (this.editTenant && this.$refs.form) {
      const refForm = this.$refs.form as any;
      if (
        !!this.editTenant.channel0Name &&
        !!this.editTenant.channel1Name &&
        !!this.editTenant.channel2Name &&
        !!this.editTenant.channel3Name
      ) {
        return refForm.checkValidity();
      }
    }
    return false;
  }

  private onSubmit() {
    this.$spinner.showSpinner();
    if (this.isEditMode) {
      tenantsService
        .updateCutConfig(
          new TenantCutConfig(
            this.editTenant.id,
            this.editTenant.channel0Name,
            this.editTenant.channel1Name,
            this.editTenant.channel2Name,
            this.editTenant.channel3Name,
          ),
        )
        .then(async () => {
          await tenantsService.store.update(this.editTenant);
          this.$emit('tenant-updated', this.editTenant);
        })
        .finally(() => this.$spinner.removeSpinner());
    }
    this.mode = Mode.View;
  }

  private checkHtml5Validity(): void {
    (this.$refs.gasChannel0_name_input! as any).checkHtml5Validity();
    (this.$refs.gasChannel1_name_input! as any).checkHtml5Validity();
    (this.$refs.gasChannel2_name_input! as any).checkHtml5Validity();
    (this.$refs.gasChannel3_name_input! as any).checkHtml5Validity();
  }

  get isEditMode(): boolean {
    return this.mode === Mode.Edit;
  }

  get isViewMode(): boolean {
    return this.mode === Mode.View;
  }
}
