
import { Component, Prop, Vue } from 'vue-property-decorator';
import { tenantsService } from '@/services/tenants.service';
import GenericKeyCopyModal from '@/components/GenericKeyCopyModal.vue';

@Component({
  components: {
    GenericKeyCopyModal,
  },
})
export default class ConnectionStringModal extends Vue {
  @Prop()
  private tenantId!: number;

  private loading = false;
  private sas: string | null = null;

  private async mounted() {
    this.loading = true;
    try {
      this.sas = await tenantsService.getBlobStorageSAS(this.tenantId);
    } catch (e) {
      this.$emit('close');
    } finally {
      this.loading = false;
    }
  }
}
