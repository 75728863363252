
import { Component, Prop, Vue } from 'vue-property-decorator';
import { shiftModelsService } from '@/services/shiftModels.service';
import { Tenant } from '@/models/tenant';
import { Mode } from '@/store/enums';
import { ShiftModel } from '@/models/shiftModel';
import MaintainShift from '@/components/shiftModels/MaintainShift.vue';
import MaintainShiftModel from '@/components/shiftModels/MaintainShiftModel.vue';
import { Shift } from '@/models/shift';
import TableActionButton from '@/components/tableWithActions/TableActionButton.vue';
import TableWithActions from '@/components/tableWithActions/TableWithActions.vue';
import { shiftsService } from '@/services/shifts.service';

@Component({
  components: {
    TableActionButton,
    TableWithActions,
  },
})
export default class ShiftModels extends Vue {
  @Prop({ required: true, default: Tenant.GetEmpty() })
  private tenant!: Tenant;

  private shiftModels: ShiftModel[] = [];

  private async created() {
    this.shiftModels = await this.fetchShiftModels();
  }

  private async fetchShiftModels() {
    this.$spinner.showSpinner();
    return await shiftModelsService
      .get(this.tenant.id)
      .finally(() => this.$spinner.removeSpinner());
  }

  private openShiftModelForm(shiftModel?: ShiftModel) {
    this.$buefy.modal.open({
      component: MaintainShiftModel,
      hasModalCard: true,
      trapFocus: true,
      parent: this,
      props: {
        tenant: this.tenant,
        shiftModel: shiftModel ?? ShiftModel.GetEmpty(),
        mode: shiftModel ? Mode.Edit : Mode.Create,
      },
      events: {
        close: async () => (this.shiftModels = await this.fetchShiftModels()),
      },
    });
  }

  private async openShiftCreationForm(shiftModel: ShiftModel) {
    this.openShiftForm({
      tenant: this.tenant,
      shiftModel,
      shift: Shift.GetEmpty(),
      mode: Mode.Create,
    });
  }

  private async openShiftEditionForm(shift: Shift) {
    this.openShiftForm({
      tenant: this.tenant,
      shiftModel: this.shiftModels.find((sm) => sm.id === shift.shiftModelId)!,
      shift,
      mode: Mode.Edit,
    });
  }

  private openShiftForm(props: any) {
    this.$buefy.modal.open({
      component: MaintainShift,
      hasModalCard: true,
      trapFocus: true,
      parent: this,
      props,
      events: {
        close: async () => (this.shiftModels = await this.fetchShiftModels()),
      },
    });
  }

  private confirmDeleteShift(shiftId: number, name: string) {
    this.openDeleteEntityConfirmationDialog(
      name,
      this.$i18n.t('shift_model.shift.name').toString().toLowerCase(),
      () => this.deleteShift(shiftId),
    );
  }

  private confirmDeleteShiftModel(shiftModelId: number, name: string) {
    this.openDeleteEntityConfirmationDialog(
      name,
      this.$i18n.t('shift_model.name').toString().toLowerCase(),
      () => this.deleteShiftModel(shiftModelId),
    );
  }

  private openDeleteEntityConfirmationDialog(name: string, entity: string, onConfirm: () => void) {
    this.$buefy.dialog.confirm({
      title: this.$i18n.t('dialog.confirm_delete_title').toString(),
      message: this.$i18n
        .t('confirmation.message', {
          action: this.$i18n.t('action.delete').toString().toLowerCase(),
          entity,
          name,
        })
        .toString(),
      confirmText: this.$i18n.t('dialog.confirm_delete').toString(),
      cancelText: this.$i18n.t(`dialog.confirm_cancel`).toString(),
      type: 'is-danger',
      hasIcon: true,
      onConfirm,
    });
  }
  private async deleteShift(id: number) {
    this.$spinner.showSpinner();
    shiftsService
      .delete(id)
      .then(async () => (this.shiftModels = await this.fetchShiftModels()))
      .finally(() => {
        this.$spinner.removeSpinner();
      });
  }

  private async deleteShiftModel(id: number) {
    this.$spinner.showSpinner();
    shiftModelsService
      .delete(id)
      .then(async () => (this.shiftModels = await this.fetchShiftModels()))
      .finally(() => {
        this.$spinner.removeSpinner();
      });
  }
}
