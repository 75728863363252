
import TableActionButton from '@/components/tableWithActions/TableActionButton.vue';
import TableWithActions from '@/components/tableWithActions/TableWithActions.vue';
import { DataPlatformTableEntry } from '@/models/dataPlatform';
import moment from 'moment';
import { Component, Vue } from 'vue-property-decorator';
import DataPlatformDetailModal from './DataPlatformDetailModal.vue';
import DataPlatformActionsDetailsModal from './DataPlatformActionsDetailsModal.vue';
import { dataPlatformService } from '@/services/dataplatform.service';
import { usersService } from '@/services/users.service';
import { downloadUrl } from '@/utils/misc';
import { canDownloadDataPlatform } from '@/utils/permissionUtils';
import i18n from '@/i18n';
import { matchIgnoringCase } from '@/utils/table';
import { DataPlatformActionEnum } from '@/models/enums/DataPlatformEnum';
import { versionIsGreaterThanOrEqualTo } from '@/utils/version';

export const HOUR_SECONDS: number = 3600;

@Component({
  methods: { canDownloadDataPlatform },
  components: {
    TableWithActions,
    TableActionButton,
  },
})
export default class DataPlatforms extends Vue {
  private data: DataPlatformTableEntry[] = [];
  private now: Date = new Date();
  private timeHandle = -1;
  private isPreparingDataPlatformDownload = false;

  private mounted() {
    this.timeHandle = window.setInterval(() => (this.now = new Date()), 1000);
    this.loadData();
  }

  private async loadData() {
    this.$spinner.showSpinner();
    try {
      this.data = await dataPlatformService.get();
    } finally {
      this.$spinner.removeSpinner();
    }
  }

  private beforeDestroy() {
    window.clearInterval(this.timeHandle);
  }

  private formatLastConfigured(date: Date | null): string {
    if (date === null) {
      return this.$t('report.unknown').toString();
    }
    return moment(date).local().format('YYYY-MM-DD, HH:mm:ss');
  }

  private getLastSeen(date: Date): string {
    const secondsDifference = moment(this.now).diff(date, 'seconds');
    if (secondsDifference >= HOUR_SECONDS) {
      const hours = String(Math.floor(secondsDifference / HOUR_SECONDS)).padStart(2, '0');
      const remainingMinutes = String(Math.floor((secondsDifference % HOUR_SECONDS) / 60)).padStart(
        2,
        '0',
      );
      const seconds = String(secondsDifference % 60).padStart(2, '0');
      return `${hours}:${remainingMinutes}:${seconds}`;
    } else {
      const minutes = String(Math.floor(secondsDifference / 60)).padStart(2, '0');
      const seconds = String(secondsDifference % 60).padStart(2, '0');
      return `${minutes}:${seconds}`;
    }
  }

  private openDetails(row: DataPlatformTableEntry) {
    this.$buefy.modal.open({
      parent: this,
      component: DataPlatformDetailModal,
      hasModalCard: true,
      trapFocus: true,
      props: {
        dataPlatform: row,
      },
    });
  }

  private async downloadDataPlatform() {
    this.isPreparingDataPlatformDownload = true;
    const url = await dataPlatformService
      .getInstallerUrl()
      .finally(() => (this.isPreparingDataPlatformDownload = false));
    downloadUrl(url);
  }

  private get isCurrentUserGlobalAdmin(): boolean {
    return usersService.store.current().isGlobalAdmin ?? false;
  }

  public confirmDelete(dataPlatform: DataPlatformTableEntry) {
    this.$buefy.dialog.confirm({
      title: i18n.t('dialog.confirm_delete_title').toString(),
      message: i18n.t('dataplatforms.delete_confirmation').toString(),
      confirmText: i18n.t('dialog.confirm_delete').toString(),
      cancelText: i18n.t(`dialog.confirm_cancel`).toString(),
      type: 'is-danger',
      hasIcon: true,
      onConfirm: () => this.deleteDataPlatform(dataPlatform.id),
    });
  }

  private deleteDataPlatform(id: number) {
    this.$spinner.showSpinner();
    dataPlatformService
      .delete(id)
      .then(() => {
        this.data = this.data.filter((x) => x.id !== id);
      })
      .finally(() => {
        this.$spinner.removeSpinner();
      });
  }

  private failingReadingJobSearch(
    dataPlatform: DataPlatformTableEntry,
    searchText: string,
  ): boolean {
    return this.failingJobSearch(dataPlatform.failingReadingJobCount, searchText);
  }

  private failingWritingJobSearch(
    dataPlatform: DataPlatformTableEntry,
    searchText: string,
  ): boolean {
    return this.failingJobSearch(dataPlatform.failingWritingJobCount, searchText);
  }

  private failingJobSearch(failingJobCount: number, searchText: string): boolean {
    return (
      failingJobCount === Number(searchText) ||
      (failingJobCount > 0 && matchIgnoringCase(`${failingJobCount} err`, searchText))
    );
  }

  private hasActionsPendingSearch(
    dataPlatform: DataPlatformTableEntry,
    searchText: string,
  ): boolean {
    return dataPlatform.hasActionsPending && matchIgnoringCase('yes', searchText);
  }

  private async restartJobs(dataPlatform: DataPlatformTableEntry) {
    this.$spinner.showSpinner();
    try {
      await dataPlatformService
        .createAction({
          dataPlatformId: dataPlatform.id,
          action: DataPlatformActionEnum.Restart,
        })
        .then(async () => await this.loadData());
    } finally {
      this.$spinner.removeSpinner();
    }
  }

  private openActionDetails(row: DataPlatformTableEntry) {
    this.$buefy.modal.open({
      parent: this,
      component: DataPlatformActionsDetailsModal,
      hasModalCard: true,
      trapFocus: true,
      props: {
        dataPlatform: row,
      },
    });
  }

  private isVisibleRestartJobs(dataPlatform: DataPlatformTableEntry): boolean {
    return versionIsGreaterThanOrEqualTo('1.2', dataPlatform.version);
  }
}
