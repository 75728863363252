
import { Component, Prop, Vue } from 'vue-property-decorator';
import AlertWorkflowStatusSelect from '@/components/inputs/AlertWorkflowStatusSelect.vue';
import { AlertWorkflowDetail, AlertWorkflowStatus } from '@/models/alertWorkflow';
import { alertWorkflowsService } from '@/services/alertWorkflows.service';
import { isEmpty, isNil, valueOrDefault } from '@/utils/misc';
import { formatWithUnit, translateKpi, translateOperator, translateVariable } from '@/utils/alerts';
import { translateDateGrouping } from '@/utils/dates';
import { router } from '@/library-services/e-service-center-router';
import moment from 'moment';

@Component({
  computed: {
    AlertWorkflowStatus: () => AlertWorkflowStatus,
  },
  methods: {
    isNil,
    valueOrDefault,
    translateKpi,
    translateDateGrouping,
    formatWithUnit,
    translateOperator,
    translateVariable,
  },
  components: { AlertWorkflowStatusSelect },
})
export default class AlertWorkflowDetails extends Vue {
  @Prop({ required: true })
  private alertWorkflowId!: number;

  private alertWorkflow: AlertWorkflowDetail | null = null;

  async mounted() {
    this.$spinner.showSpinner();
    try {
      this.alertWorkflow = await alertWorkflowsService.getDetail(this.alertWorkflowId);
    } finally {
      this.$spinner.removeSpinner();
    }
  }

  private hasEventCodes(alertWorkflow: AlertWorkflowDetail) {
    return !isEmpty(alertWorkflow.alertOccurrence.eventCodes);
  }

  private hasEventSources(alertWorkflow: AlertWorkflowDetail) {
    return !isEmpty(alertWorkflow.alertOccurrence.eventSources);
  }

  private formatTime(date: Date) {
    return moment(date).format('YYYY-MM-DD, HH:mm');
  }

  private async goToEquipmentCareConsole(alertWorkflow: AlertWorkflowDetail) {
    this.$emit('close');
    await router.goToEquipmentAlertTabOnCareConsole(
      alertWorkflow.customer.tenantIdDh,
      alertWorkflow.equipment.deviceId,
    );
  }

  private async goToWorkCenterConsole(alertWorkflow: AlertWorkflowDetail) {
    this.$emit('close');
    await router.goToEquipmentWorkCenterConsole(
      alertWorkflow.customer.tenantIdDh,
      alertWorkflow.equipment.deviceId,
    );
  }
}
