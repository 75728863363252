
import { Component, Vue } from 'vue-property-decorator';
import { MaterialGroup } from '@/models/materialGroup';
import { materialsService } from '@/services/materials.service';
import { Material } from '@/models/material';

@Component({})
export default class AssignMaterialToGroup extends Vue {
  public materialGroups: MaterialGroup[] = [];
  public material: Material = {} as Material;

  public materialGroupId: number = 0;

  public isComponentModalActive = false;

  public async openModalExternal(
    materialGroups: MaterialGroup[],
    material: Material,
  ): Promise<void> {
    this.$spinner.showSpinner();
    this.materialGroups = materialGroups;
    this.material = material;
    await this.initModal();
    this.$spinner.removeSpinner();
  }

  public async openModal(): Promise<void> {
    this.$spinner.showSpinner();
    await this.initModal();
    this.$spinner.removeSpinner();

    this.isComponentModalActive = true;
  }

  public handleSelect(value: any) {
    this.materialGroupId = value;
  }

  public async onSubmit() {
    this.$spinner.showSpinner();
    this.material.materialGroupId = this.materialGroupId;

    materialsService
      .update(this.material)
      .then(() => {
        this.isComponentModalActive = false;
        this.$emit('onClose');
      })
      .finally(() => this.$spinner.removeSpinner());
  }

  private async initModal() {
    this.isComponentModalActive = true;
  }
}
