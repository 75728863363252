
import { Wizard } from '@/components/wizard';
import { Component, Prop, Vue } from 'vue-property-decorator';
import PersonalDataWizardStep from './steps/PersonalDataWizardStep.vue';
import { User } from '@/models/user';
import SummaryWizardStep from './steps/SummaryWizardStep.vue';
import { UserUpdate, usersService } from '@/services/users.service';

@Component({
  components: {
    PersonalDataWizardStep,
    SummaryWizardStep,
    Wizard,
  },
})
export default class GlobalAdminWizard extends Vue {
  @Prop({ default: null })
  private user!: User | null;

  private userCopy: User | null = null;

  private created() {
    this.userCopy = this.user ?? User.emptyGlobalAdmin();
  }

  private async submit() {
    this.$spinner.showSpinner();
    try {
      if (this.isEdit) {
        const updateUser: UserUpdate = User.GetCopy(this.userCopy!);
        updateUser.isAcceptedTermsOfAgreement = undefined;
        updateUser.isAutoplay = undefined;
        updateUser.autoplayPeriod = undefined;
        updateUser.dashboards = undefined;
        await usersService.update(updateUser);
      } else {
        await usersService.create(this.userCopy!);
      }
      this.$emit('close');
    } finally {
      this.$spinner.removeSpinner();
    }
  }

  private get isEdit(): boolean {
    return !!this.user;
  }
}
