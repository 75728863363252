// --------------------------------------------------------------------------------
// <copyright file="auth.service.ts" company="Bystronic Laser AG">
//  Copyright (C) Bystronic Laser AG 2021-2024
// </copyright>
// --------------------------------------------------------------------------------

import { ConfigurationSpa } from '@/models/configurationSpa';
import store from '@/store';
import { PersistentActions } from '@/store/persistent/enums';
import { UserManager, User, WebStorageStateStore, Log } from 'oidc-client';

export default class AuthService {
  private userManager: UserManager;

  constructor() {
    const spaConfig: ConfigurationSpa = JSON.parse(sessionStorage.getItem('spa.config') || '');

    const userManagerSettings: any = spaConfig.oidcConfiguration;
    userManagerSettings.userStore = new WebStorageStateStore({ store: window.localStorage });
    userManagerSettings.automaticSilentRenew = true;
    userManagerSettings.includeIdTokenInSilentRenew = true;

    // Display user manager traces in console
    if (spaConfig.enableAuthLog) {
      Log.logger = console;
      Log.level = Log.DEBUG;
    }

    this.userManager = new UserManager(userManagerSettings);
  }

  async getUser(): Promise<User | null> {
    return await this.userManager.getUser();
  }

  async login(): Promise<void> {
    return await this.userManager.signinRedirect();
  }

  async completeSignIn(url: string): Promise<User> {
    return await this.userManager.signinCallback(url);
  }

  async logout(): Promise<void> {
    await store.dispatch(PersistentActions.ResetState);
    return await this.userManager.signoutRedirect();
  }

  async getAccessToken(): Promise<string | null> {
    return this.userManager.getUser().then((data) => {
      return data?.access_token ?? null;
    });
  }

  async isLoggedIn(): Promise<boolean> {
    const user = await this.userManager.getUser();
    if (!user || user.expired === undefined) {
      return false;
    }

    return !user.expired;
  }
}
