
import { Component, Vue } from 'vue-property-decorator';
import i18n from '@/i18n';
import { PersistentActions } from '@/store/persistent/enums';
import { getLocaleFullName } from '@/locale/dictLocale';
import { localeChanged } from 'vee-validate';

@Component({
  methods: { getLocaleFullName },
})
export default class SelectLanguage extends Vue {
  private locales = i18n.availableLocales;

  private setLocale(locale: string) {
    i18n.locale = locale;
    this.$store.dispatch(PersistentActions.SetLocale, locale);
    localeChanged();
    this.$emit('select-language', locale);
    this.$emit('close');
  }

  private get currentLocale() {
    return i18n.locale;
  }
}
