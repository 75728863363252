
import { Component, Prop, Vue } from 'vue-property-decorator';
import { bendPartCategoryService } from '@/services/bendPartCategory.service';
import { Tenant } from '@/models/tenant';
import { BendPartCategory } from '@/models/bendPartCategory';

@Component({})
export default class BendPartCategoryConfig extends Vue {
  @Prop({ required: true, default: Tenant.GetEmpty() })
  private tenant!: Tenant;

  private isReadOnly = true;
  private bendPartCategory = BendPartCategory.GetEmpty();

  private mounted() {
    this.$spinner.showSpinner();
    bendPartCategoryService
      .get(this.tenant.id)
      .then((bendPartCategory) => {
        this.bendPartCategory = bendPartCategory;
      })
      .finally(() => this.$spinner.removeSpinner());
  }

  private get calculatedSimpleFrom(): string {
    return '0'.padEnd(8, ' ') + '-        ';
  }

  private get calculatedMediumFrom(): string {
    return (
      (this.bendPartCategory.simpleTo
        ? (Number(this.bendPartCategory.simpleTo) + 1).toString()
        : ''
      ).padEnd(8, ' ') + '-        '
    );
  }

  private get calculatedComplexFrom(): string {
    return this.bendPartCategory.mediumTo ? ('>' + this.bendPartCategory.mediumTo).toString() : '';
  }

  private get isFormValid() {
    if (this.bendPartCategory && this.$refs.form) {
      const refForm = this.$refs.form as any;
      if (!!this.bendPartCategory.simpleTo && !!this.bendPartCategory.mediumTo) {
        return (
          refForm.checkValidity() && this.bendPartCategory.simpleTo < this.bendPartCategory.mediumTo
        );
      }
    }
    return false;
  }

  private startEdit() {
    this.isReadOnly = false;
  }

  private cancelEdition() {
    this.isReadOnly = true;
  }

  private handleChange(event: any): void {
    this.bendPartCategory = {
      ...this.bendPartCategory,
      [event?.target?.name]: event?.target?.value,
    };
  }

  private async onSubmit() {
    this.$spinner.showSpinner();
    bendPartCategoryService
      .update(this.bendPartCategory)
      .then(() => {
        this.isReadOnly = true;
      })
      .finally(() => this.$spinner.removeSpinner());
  }
}
