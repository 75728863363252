
import { WizardStep } from '@/components/wizard';
import { User } from '@/models/user';
import { Location } from '@/models/location';
import { locationsService } from '@/services/locations.service';
import { Component, Prop, Vue } from 'vue-property-decorator';
import { ConsoleEnum } from '@/models/enums/ConsoleEnum';
import moment from 'moment';
import i18n from '@/i18n';
import { isEmpty } from '@/utils/misc';
import OtherMembershipsSummarySection from '@/components/users/wizards/steps/OtherMembershipsSummarySection.vue';
import { UserType } from '@/models/userType';

const DATE_FORMAT = 'D/M/YYYY';

@Component({
  methods: { isEmpty },
  components: {
    OtherMembershipsSummarySection,
    WizardStep,
  },
})
export default class SummaryWizardStep extends Vue {
  @Prop({ required: true })
  private user!: User;

  @Prop({ default: false, type: Boolean })
  private showSubsidiaryMembership!: boolean;

  @Prop({ default: false, type: Boolean })
  private showCustomerMembership!: boolean;

  private locations: Location[] = [];

  private async onEnter() {
    if (this.showCustomerMembership) {
      await this.loadLocations();
    }
  }

  private async loadLocations() {
    this.$spinner.showSpinner();
    try {
      this.locations = await locationsService.get(this.user.customerId);
    } finally {
      this.$spinner.removeSpinner();
    }
  }

  private getLocationName(id: number): string | undefined {
    return this.locations.find((x) => x.id === id)?.name;
  }

  private get consoles(): ConsoleEnum[] {
    if (this.showCustomerMembership) {
      return this.user.consoles;
    }

    switch (this.user.userType) {
      case UserType.TechnologyUser:
        return [ConsoleEnum.Technology];
      case UserType.ServiceGlobal:
      case UserType.ServiceUser:
        return [ConsoleEnum.WCC, ConsoleEnum.Care];
      default:
        return [
          ConsoleEnum.WCC,
          ConsoleEnum.BC,
          ConsoleEnum.SFC,
          ConsoleEnum.Care,
          ConsoleEnum.Technology,
        ];
    }
  }

  private getFormattedConsoleDateRange(console: ConsoleEnum): string {
    const from = moment(this.user.getConsoleActivationDate(console)).format(DATE_FORMAT);
    const toDate = this.user.getConsoleEndDate(console);
    if (toDate === null) {
      return i18n.t('wizards.user_creation.summary.console_from', { from }).toString();
    } else {
      const to = moment(toDate).format(DATE_FORMAT);
      return i18n.t('wizards.user_creation.summary.console_from_to', { from, to }).toString();
    }
  }

  private get userHasEnabledWCC(): boolean {
    return this.user.consoles.includes(ConsoleEnum.WCC);
  }
}
