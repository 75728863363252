
import { Component, Prop, Vue } from 'vue-property-decorator';
import { materialGroupsService } from '@/services/materialGroups.service';
import { MaterialGroup } from '@/models/materialGroup';
import { Tenant } from '@/models/tenant';
import { materialsService } from '@/services/materials.service';
import TableActionButton from '../tableWithActions/TableActionButton.vue';
import TableWithActions from '../tableWithActions/TableWithActions.vue';

@Component({
  components: {
    TableActionButton,
    TableWithActions,
  },
})
export default class ManageMaterialGroup extends Vue {
  @Prop({ required: true, default: MaterialGroup.GetEmpty() })
  public tenant!: Tenant;
  @Prop({ required: false, default: null })
  public materialGroupId!: number;
  @Prop({ required: true, default: [] })
  public materialGroups!: MaterialGroup[];

  @Prop({ default: false })
  public disabled!: boolean;

  public materialGroup: MaterialGroup = MaterialGroup.GetEmpty();

  public mounted() {
    this.refreshData();
  }

  public closeModal() {
    this.$emit('close');
  }

  public async refreshData() {
    this.$spinner.showSpinner();
    materialGroupsService
      .getDetails(this.materialGroupId)
      .then((data: MaterialGroup) => {
        this.materialGroup = data;
      })
      .finally(() => this.$spinner.removeSpinner());
  }

  public confirmUnassign(id: number, name: string): void {
    this.$buefy.dialog.confirm({
      title: this.$i18n.t('dialog.confirm_title').toString(),
      message: this.$i18n.t('material.dialog.unassign').toString() + ` <b>${name}</b>?`,
      confirmText: this.$i18n.t('dialog.confirm_unassign').toString(),
      cancelText: this.$i18n.t(`dialog.confirm_cancel`).toString(),
      type: 'is-danger',
      hasIcon: true,
      onConfirm: () => this.unassignMaterial(id),
    });
  }

  private unassignMaterial(id: number): void {
    this.$spinner.showSpinner();
    const material = this.materialGroup.materials?.filter((m) => m.id === id)[0];
    const materialGroup = this.materialGroups.filter((g) => g.name === 'Unknown')[0];

    if (!material || !materialGroup) {
      return;
    }

    material.materialGroupId = materialGroup.id;
    materialsService
      .update(material)
      .then(() => {
        this.materialGroup.materials = this.materialGroup.materials?.filter((x) => x.id !== id);
      })
      .finally(() => {
        this.$spinner.removeSpinner();
      });
  }
}
