
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { locationsService } from '@/services/locations.service';
import { Location } from '@/models/location';
import { ValidationProvider } from 'vee-validate';

@Component({
  components: {
    'validation-provider': ValidationProvider,
  },
})
export default class LocationsSelect extends Vue {
  @Prop({ default: '' })
  private value!: number;

  @Prop()
  private rules?: any;

  @Prop({ default: false })
  private disabled!: boolean;

  @Prop({ required: true, default: null })
  private tenantId!: number;

  // FIXME: it should be used in the template instead of value
  private innerValue: number | null = null;
  private locations: Location[] = [];

  private mounted(): void {
    if (this.value) {
      this.innerValue = this.value;
    }

    this.fetchLocations();
  }

  private async fetchLocations() {
    this.$spinner.showSpinner();
    this.locations = await locationsService
      .get(this.tenantId)
      .finally(() => this.$spinner.removeSpinner());
  }

  @Watch('value')
  public onValueChanged(locationId: number) {
    this.$emit('input', locationId);
    this.$emit('change', locationId);
  }

  private get required(): boolean {
    return this.rules?.required || this.rules?.includes?.('required');
  }
}
