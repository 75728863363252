
import { Component, Prop, Vue } from 'vue-property-decorator';
import TableActions from '@/components/tableWithActions/TableActions.vue';
import { SortOrder } from '@/models/enums/SortOrder';

type PaginationPosition = 'top' | 'bottom';
type SortIconSize = 'is-small' | 'is-medium' | 'is-large' | '';

/**
 * @deprecated: To be replaced by TableWithActions2 in the (near, I hope) future.
 */
@Component({
  components: {
    TableActions,
  },
})
export default class TableWithActions extends Vue {
  @Prop({ required: true })
  private data!: any[];

  @Prop({ default: true, type: Boolean })
  private paginated!: boolean;

  @Prop({ default: 10 })
  private rowsPerPage!: number;

  @Prop({ default: false, type: Boolean })
  private paginationSimple!: boolean;

  @Prop({ default: 'bottom' })
  private paginationPosition!: PaginationPosition;

  @Prop({ default: SortOrder.Ascending })
  private defaultSortDirection!: SortOrder;

  @Prop({ default: 'arrow-up' })
  private sortIcon!: string;

  @Prop({ default: 'is-small' })
  private sortIconSize!: SortIconSize;

  @Prop({ required: false })
  private defaultSort?: string | string[];

  @Prop({ required: false })
  private titleField?: string;

  @Prop({ required: false })
  private titleLabel?: string;

  @Prop({ default: false, type: Boolean })
  private loading!: boolean;

  @Prop({ default: false, type: Boolean })
  private hoverable!: boolean;

  @Prop({ default: false, type: Boolean })
  private detailed!: boolean;

  @Prop()
  private detailKey?: string;

  private get actionsWereDeclared(): boolean {
    return !!this.$scopedSlots.actions;
  }

  private get defaultTitleLabel(): string {
    return this.$t('form.name').toString();
  }

  private get hasBottomLeftSlot(): boolean {
    return 'bottom-left' in this.$slots || 'bottom-left' in this.$scopedSlots;
  }

  private get hasDetailsSlot(): boolean {
    return 'detail' in this.$slots || 'detail' in this.$scopedSlots;
  }
}

export function truncatedValueColumnEllipsisTitle(row: any, column: any) {
  return {
    title: row[column.field],
  };
}
