// --------------------------------------------------------------------------------
// <copyright file="metrics.service.ts" company="Bystronic Laser AG">
//  Copyright (C) Bystronic Laser AG 2021-2024
// </copyright>
// --------------------------------------------------------------------------------

import axios from 'axios';
import { BaseUrl } from '@/models/constants';
import { ListOrObject } from '@/models/metrics/listOrObject';
import { AlertOccurrence, AlertOccurrenceDTO } from '@/models/alertOccurrence';
import { WidgetEnum } from '@/models/enums/WidgetEnum';
import { RequestCanceledError } from '@/services/requestCanceledError';
import { FilterTimeSpanEnum } from '@/models/enums/FilterTimeSpanEnum';
import { FilterTimeAxisSpanEnum } from '@/models/enums/FilterTimeAxisSpanEnum';
import { isEmpty } from '@/utils/misc';
import {
  CutCurrentWorkloadData,
  ImportantMessageLevel,
  SalesTopBySalesData,
} from '@/models/Charts/chartsData';
import { Memoize } from 'typescript-memoize';
import { AggregateEnum } from '@/models/enums/AggregateEnum';
import { FunctionMetric } from '@/models/enums/FunctionMetric';
import { PlanState } from '@/models/enums/PlanState';
import { SortOrder } from '@/models/enums/SortOrder';

// The ignored fields are used by TechnologyConsoleTenantDevices through
// TechnologyTenantDevicesTableItem.
//
// Related data structures:
// - TechnologyTenantDevicesTableItem
export interface DeviceVersion {
  deviceid: string | null;
  tenantid: number | null; // Ignored
  FT_Material: string | null; // Ignored
  FT_Serial: string | null; // Ignored
  SW_Version: string | null;
  BeamShaperSerial: string | null; // Ignored
  ByMotionVersion: string | null;
  ByTransVersion: string | null;
  ByVisionVersion: string | null;
  CuttingHeadVersion: string | null; // Ignored
  MachineType: string | null;
  CuttingHeadType: string | null;
  EquipmentNumber: string | null;
}

interface DevicesMetricsFilter {
  tenantIdDh: number;
  deviceIds: string[];
  shifts: number[];
  timeSpan: FilterTimeSpanEnum;
  timeAxisSpan: FilterTimeAxisSpanEnum;
  startDate: string;
  endDate: string;
  statesOnly: boolean;
  planStates: PlanState[];
}

interface SMBSMetricsBCFilter {
  startDate: string;
  endDate: string;
  customerNo: string;
  axisTimespan: FilterTimeAxisSpanEnum;
  paramNumber: number;
  date: string;
  startAmount: number;
  endAmount: number;
  groupNumber: number;
  target: number;
  belowTarget: boolean;
  basedOnHistoricalData: boolean;
}

interface SMBSMetricsSFCFilter {
  startDate: string;
  endDate: string;
  axisTimespan: FilterTimeAxisSpanEnum;
  paramNumber: number;
}

export interface SSCMetricsFilter {
  dateFrom: string;
  dateTo: string;
  dateGrouping: FilterTimeAxisSpanEnum;

  modules: string[];
  messageLevel: number;
  eventCodes: string[];

  pageNumber: number;
  rowsPerPage: number;

  sortField: string;
  sortOrder: SortOrder;

  metric: FunctionMetric;

  last24h: boolean;

  historical: boolean;

  aggregate: AggregateEnum;

  versionInformation: boolean;
  interruptIfV4: boolean;
}

interface WebMetricsFilter {
  deviceId: string;
  startDate: string;
  endDate: string;
}

class MetricsService {
  async getDevicesMetrics<T>(
    kpiName: string,
    filter: Partial<DevicesMetricsFilter>,
    controller: AbortController,
  ): Promise<ListOrObject<T>> {
    return await this.getMetric<T>(
      'devices',
      {
        ...filter,
        kpiName,
      },
      controller,
    );
  }

  async getDevicesCurrentWorkload(
    deviceIds: string | string[],
    tenantIdDh: number,
    controller: AbortController,
  ) {
    return (
      (await this.getDevicesMetrics<CutCurrentWorkloadData>(
        WidgetEnum.LaserCurrentWorkload,
        {
          deviceIds: Array.isArray(deviceIds) ? deviceIds : [deviceIds],
          tenantIdDh,
          planStates: [PlanState.Started],
        },
        controller,
      )) ?? {
        currentMessages: [],
        cuttingPlanInfo: [],
      }
    );
  }

  async getSMBSMetricsBC<T>(
    kpiName: string,
    tenantIdDh: number,
    filter: Partial<SMBSMetricsBCFilter>,
    controller: AbortController,
  ): Promise<ListOrObject<T>> {
    return await this.getMetric<T>(
      'smbs-metrics-bc',
      {
        ...filter,
        kpiName,
        tenantIdDh,
      },
      controller,
    );
  }

  @Memoize({
    expiring: 10_000, // milliseconds
    hashFunction: (
      tenantIdDh: number,
      numberOfSalespeople: number,
      startDate?: string,
      endDate?: string,
    ) => `${tenantIdDh}${numberOfSalespeople}${startDate}${endDate}`,
  })
  async getCachedTopSalespeopleData(
    tenantIdDh: number,
    numberOfSalespeople: number,
    startDate: string,
    endDate: string,
    controller: AbortController,
  ): Promise<SalesTopBySalesData[] | null> {
    return this.getSMBSMetricsBC<SalesTopBySalesData[]>(
      WidgetEnum.SalesTopSalespeople,
      tenantIdDh,
      {
        paramNumber: numberOfSalespeople,
        startDate,
        endDate,
      },
      controller,
    );
  }

  async getSMBSMetricsSFC<T>(
    kpiName: string,
    tenantIdDh: number,
    filter: Partial<SMBSMetricsSFCFilter>,
    controller: AbortController,
  ): Promise<ListOrObject<T>> {
    return await this.getMetric<T>(
      'smbs-metrics-sfc',
      {
        ...filter,
        kpiName,
        tenantIdDh,
      },
      controller,
    );
  }

  async getSSCMetrics<T>(
    kpiName: string,
    tenantIdDh: number,
    deviceId: string,
    filter: Partial<SSCMetricsFilter>,
    controller: AbortController,
  ): Promise<ListOrObject<T>> {
    return await this.getMetric<T>(
      'ssc-metrics',
      {
        ...filter,
        kpiName,
        tenantIdDh,
        deviceId,
      },
      controller,
    );
  }

  async getAlertOccurrences(
    deviceId: string,
    startDate: string,
    endDate: string,
    abortController: AbortController,
  ): Promise<AlertOccurrence[]> {
    return (
      await this.getWebMetrics<AlertOccurrenceDTO[]>(
        WidgetEnum.CareAlertOccurrencesTable,
        {
          deviceId,
          startDate,
          endDate,
        },
        abortController,
      )
    ).map((alertOccurrence) => this.fixAlertOccurrenceListFields(alertOccurrence));
  }

  private fixAlertOccurrenceListFields(alertOccurrence: AlertOccurrenceDTO): AlertOccurrence {
    return {
      isActive: alertOccurrence.isActive,
      dateGrouping: alertOccurrence.dateGrouping,
      startTimeSpan: alertOccurrence.startTimeSpan,
      latestTimeSpan: alertOccurrence.latestTimeSpan,
      metric: alertOccurrence.metric,
      latestValue: alertOccurrence.latestValue,
      operator: alertOccurrence.operator,
      threshold: alertOccurrence.threshold,
      variable: alertOccurrence.variable,
      // Fixes these fields:
      eventSources: alertOccurrence.eventSources?.split(','),
      eventCodes: alertOccurrence.eventCodes?.split(','),
    };
  }

  async getWebMetrics<T>(
    kpiName: string,
    filter: Partial<WebMetricsFilter>,
    abortController: AbortController,
  ): Promise<ListOrObject<T>> {
    return await this.getMetric<T>(
      'web',
      {
        ...filter,
        kpiName,
      },
      abortController,
    );
  }

  async getDevicesInfo<T>(
    tenantIdDh: number,
    controller: AbortController,
  ): Promise<ListOrObject<T>> {
    return await this.getMetric<T>(
      'devices-info',
      {
        tenantIdDh,
      },
      controller,
    );
  }

  private async getMetric<T>(
    method: string,
    params: any,
    controller: AbortController,
  ): Promise<ListOrObject<T>> {
    const response = await axios.get<ListOrObject<T>>(`${BaseUrl}/metrics/${method}`, {
      params,
      signal: controller.signal,
    });

    if (controller.signal.aborted) {
      throw new RequestCanceledError();
    }

    return response.data;
  }

  getEventTypeParameter(eventTypes: ImportantMessageLevel[]): number | undefined {
    const numberOfLevels = 2; // There're just Error and Warning levels for the moment
    if (isEmpty(eventTypes) || eventTypes.length === numberOfLevels) {
      return undefined; // If not specified, the backend to returns all levels
    }

    if (eventTypes.includes(ImportantMessageLevel.Error)) {
      return 3;
    } else {
      return 2;
    }
  }
}

export const metricsService = new MetricsService();
