import { render, staticRenderFns } from "./AlertsActionsSelect.vue?vue&type=template&id=641979aa&scoped=true"
import script from "./AlertsActionsSelect.vue?vue&type=script&lang=ts"
export * from "./AlertsActionsSelect.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "641979aa",
  null
  
)

export default component.exports