
import { Component, Vue } from 'vue-property-decorator';
import { User, UserAuthenticationType } from '@/models/user';
import { UserType } from '@/models/userType';
import { Tenant } from '@/models/tenant';
import { usersService } from '@/services/users.service';
import { Subsidiary } from '@/models/subsidiary';
import { subsidiariesService } from '@/services/subsidiaries.service';
import { tenantsService } from '@/services/tenants.service';
import { hasCurrentUserPermission } from '@/utils/permissionUtils';
import ResetPassword from '@/components/users/ResetPassword.vue';
import TableActionButton from '@/components/tableWithActions/TableActionButton.vue';
import TableWithActions from '@/components/tableWithActions/TableWithActions.vue';
import UserCreationDropdown from '@/components/users/wizards/UserCreationDropdown.vue';
import {
  openCustomerUserWizard,
  openGlobalAdminWizard,
  openServiceGlobalUserWizard,
  openServiceUserWizard,
  openSubsidiaryAdminWizard,
  openTechnologyUserWizard,
} from '@/components/users/wizards/modals';
import { matchIgnoringCase } from '@/utils/table';

interface UserItem {
  user: User;
  belongsToName: string;
}

@Component({
  components: {
    UserCreationDropdown,
    TableActionButton,
    TableWithActions,
  },
})
export default class Users extends Vue {
  private usersData: UserItem[] = [];
  private subsidiaries: Subsidiary[] = [];
  private customers: Tenant[] = [];

  private async created() {
    await this.getSubsidiaries();
    await this.getCustomers();
    await this.getUsers();
  }

  private confirmDelete(id: number, name: string): void {
    this.$buefy.dialog.confirm({
      title: this.$i18n.t('dialog.confirm_delete_title').toString(),
      message: this.$i18n
        .t('confirmation.message', {
          action: this.$i18n.t('action.delete').toString().toLowerCase(),
          entity: this.$i18n.t('user.name').toString().toLowerCase(),
          name,
        })
        .toString(),
      confirmText: this.$i18n.t('dialog.confirm_delete').toString(),
      cancelText: this.$i18n.t(`dialog.confirm_cancel`).toString(),
      type: 'is-danger',
      hasIcon: true,
      onConfirm: () => this.deleteUser(id),
    });
  }

  private editUser(user: User) {
    if (user.isGlobalAdmin) {
      openGlobalAdminWizard(this, user, this.modalClose);
    } else if (user.isServiceGlobal) {
      openServiceGlobalUserWizard(this, user, this.modalClose);
    } else if (user.isServiceUser) {
      openServiceUserWizard(this, user, null, this.modalClose);
    } else if (user.isSubsidiaryAdmin) {
      openSubsidiaryAdminWizard(this, user, null, this.modalClose);
    } else if (user.isCustomer) {
      openCustomerUserWizard(this, user, this.modalClose);
    } else if (user.isTechnologyUser) {
      openTechnologyUserWizard(this, user, this.modalClose);
    }
  }

  private resetPassword(user: User) {
    this.$buefy.modal.open({
      parent: this,
      component: ResetPassword,
      hasModalCard: true,
      trapFocus: true,
      props: {
        id: user.id,
        email: user.email,
      },
    });
  }

  private async modalClose() {
    await this.getUsers();
  }

  private subsidiaryName(subsidiaryId: number) {
    return this.subsidiaries.find((s) => s.id === subsidiaryId)?.name ?? '-';
  }

  private customerName(customerId: number) {
    return this.customers.find((s) => s.id === customerId)?.name ?? '-';
  }

  private getBelongsToName(user: User) {
    if (user.isSubsidiaryAdmin || user.isServiceUser) {
      return this.subsidiaryName(user.subsidiaryId!);
    } else if (user.isCustomer) {
      return this.customerName(user.customerId!);
    } else {
      return '';
    }
  }

  private async getUsers() {
    this.$spinner.showSpinner();
    try {
      this.usersData = (await usersService.get()).map((user) => ({
        user,
        belongsToName: this.getBelongsToName(user),
      }));
    } finally {
      this.$spinner.removeSpinner();
    }
  }

  private async getSubsidiaries() {
    if (hasCurrentUserPermission(UserType.SubsidiaryAdmin)) {
      this.$spinner.showSpinner();
      this.subsidiaries = await subsidiariesService
        .get()
        .finally(() => this.$spinner.removeSpinner());
    }
  }

  private async getCustomers() {
    if (hasCurrentUserPermission(UserType.CustomerUser)) {
      this.$spinner.showSpinner();
      this.customers = await tenantsService.get().finally(() => this.$spinner.removeSpinner());
    }
  }

  private deleteUser(id: number): void {
    this.$spinner.showSpinner();
    usersService
      .delete(id)
      .then(() => {
        this.usersData = this.usersData.filter((userItem) => userItem.user.id !== id);
      })
      .finally(() => {
        this.$spinner.removeSpinner();
      });
  }

  private get currentUser(): User {
    return usersService.store.current();
  }

  private get canCreateUsers(): boolean {
    return hasCurrentUserPermission(UserType.CustomerAdmin);
  }

  private filterType(userItem: UserItem, filter: string) {
    const translatedType = this.$t(`user.user_type.${userItem.user.userType}`) as string;
    return matchIgnoringCase(translatedType, filter);
  }

  private sortByType(a: UserItem, b: UserItem, isAsc: boolean) {
    const translatedTypeA = this.$t(`user.user_type.${a.user.userType}`) as string;
    const translatedTypeB = this.$t(`user.user_type.${b.user.userType}`) as string;
    return isAsc
      ? translatedTypeA.localeCompare(translatedTypeB)
      : translatedTypeB.localeCompare(translatedTypeA);
  }

  private canResetPasswordOn(user: User): boolean {
    return user.authenticationType === UserAuthenticationType.Local;
  }

  private canEdit(editingUser: User): boolean {
    return (
      this.currentUser.isGlobalAdmin ||
      this.currentUser.equals(editingUser) ||
      (this.currentUser.isSubsidiaryAdmin &&
        !editingUser.isGlobalAdmin &&
        !editingUser.isSubsidiaryAdmin) ||
      (this.currentUser.isCustomerAdmin &&
        !editingUser.isGlobalAdmin &&
        !editingUser.isCustomerAdmin)
    );
  }
}
