// --------------------------------------------------------------------------------
// <copyright file="signalr.service.ts" company="Bystronic Laser AG">
//  Copyright (C) Bystronic Laser AG 2021-2024
// </copyright>
// --------------------------------------------------------------------------------

import { ConfigurationSpa } from '@/models/configurationSpa';
import { PersistentActions, PersistentGetters } from '@/store/persistent/enums';
import RootState from '@/store/rootState';
import { HubConnection, HubConnectionBuilder, LogLevel } from '@aspnet/signalr';
import { Store } from 'vuex';
import { ToastProgrammatic as Toast } from 'buefy';
import { notificationsService } from './notifications.service';
import VueI18n from 'vue-i18n';
import { UserCustomNotificationStatus } from '@/models/customNotifications';
import moment from 'moment';
import { User } from '@/models/user';
import { UserAlertStatus } from '@/models/userAlertStatus';
import { AlertWorkflowNotification } from '@/models/AlertWorkflowNotification';
import { Logger } from '@/utils/logger';

export class SignalrService {
  private store: Store<RootState>;
  private i18n: VueI18n;
  private connection: HubConnection;
  private userId: number = -1;
  private groupName?: string;

  constructor(store: Store<RootState>, i18n: VueI18n) {
    this.store = store;
    this.i18n = i18n;
    const spaConfig: ConfigurationSpa = JSON.parse(sessionStorage.getItem('spa.config') || '');

    const user: User = this.store.getters[PersistentGetters.CurrentUser];
    this.userId = user.id;
    this.groupName = user.customerId?.toString();

    this.connection = new HubConnectionBuilder()
      .withUrl(spaConfig.signalrHubEndpoint)
      .configureLogging(LogLevel.Information)
      .build();

    this.connection.on('Send', (message: any) => this.messageReceived(message));
    this.connection.on('Alert', () => this.alertNotified());
  }

  connect() {
    this.connection
      .start()
      .then(() => {
        this.connection.invoke('SignIn', this.userId);
        if (!!this.groupName) {
          this.connection.invoke('AddToGroup', this.groupName);
        }
      })
      .catch((e: any) => {
        throw e;
      });
  }

  disconnect() {
    this.connection.invoke('SignOut', this.userId);
    if (!!this.groupName) {
      this.connection.invoke('RemoveFromGroup', this.groupName);
    }
    this.connection.stop();
  }

  private messageReceived(message: string) {
    Toast.open({
      duration: 5000,
      message,
      position: 'is-top',
      type: 'is-warning is-light',
    });
  }

  private alertNotified() {
    notificationsService.get().then((notifications) => {
      // Take the last one. Endpoint sorts them by descending timestamp
      const message = this.generateNotificationMessage(notifications[0]);

      if (!message) {
        return;
      }

      Toast.open({
        duration: 5000,
        message,
        position: 'is-top',
        type: 'is-warning is-light',
      });

      navigator.serviceWorker.ready.then((registration) => {
        registration.showNotification(message);
      });
    });
  }

  private generateNotificationMessage(
    notification: UserAlertStatus | AlertWorkflowNotification | UserCustomNotificationStatus,
  ) {
    if (notification instanceof UserCustomNotificationStatus) {
      return this.generateUserCustomNotificationStatusMessage(notification);
    } else if (notification instanceof AlertWorkflowNotification) {
      return this.generateAlertWorkflowNotificationMessage(notification);
    } else if (notification instanceof UserAlertStatus) {
      return this.generateUserAlertStatusNotificationMessage(notification);
    }

    Logger.error('Invalid notification type', notification);
  }

  private generateUserCustomNotificationStatusMessage(notification: UserCustomNotificationStatus) {
    const timestampNotification: string = this.formatTimestampNotification(
      notification.timestampNotification,
    );
    return `${timestampNotification} ${notification.messageTxt}`;
  }

  private generateAlertWorkflowNotificationMessage(notification: AlertWorkflowNotification) {
    const params = {
      deviceName: notification.deviceName,
      customerName: notification.customerName,
    };
    return this.i18n.t('alert.alert_workflow_notification_message', params).toString();
  }

  private generateUserAlertStatusNotificationMessage(notification: UserAlertStatus) {
    const params: any = {
      metric: this.i18n.t(`alert.metric.${notification.metric}`),
      operator: this.i18n.t(`alert.operator.${notification.operator}`),
      thresholdValue: notification.thresholdValue.toString(),
      device: notification.deviceName ?? this.i18n.t('alert.all_devices'),
      currentValue: this.i18n.n(notification.currentValue, { maximumSignificantDigits: 2 }),
      timestampNotification: this.formatTimestampNotification(notification.timestampNotification),
    };

    return this.i18n.t('alert.message', params).toString();
  }

  private formatTimestampNotification(timestampNotification: string | Date) {
    return moment(`${timestampNotification}+00:00`).format('YYYY-MM-DD, HH:mm:ss');
  }
}
