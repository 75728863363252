
import { Component, Prop, Vue } from 'vue-property-decorator';
import { Tenant } from '@/models/tenant';
import { Mode } from '@/store/enums';
import { ShiftModel } from '@/models/shiftModel';
import { shiftModelsService } from '@/services/shiftModels.service';
import i18n from '@/i18n';

@Component({})
export default class MaintainShiftModel extends Vue {
  @Prop({ required: true })
  private tenant!: Tenant;

  @Prop({ required: true })
  private mode!: Mode;

  @Prop({ required: true })
  private shiftModel!: ShiftModel;

  private editableShiftModel = ShiftModel.GetEmpty();

  private async mounted() {
    this.$spinner.showSpinner();
    if (this.isCreateMode) {
      this.editableShiftModel = ShiftModel.GetEmpty();
      if (this.tenant) {
        this.editableShiftModel.tenantId = this.tenant.id;
      }
    } else {
      this.editableShiftModel = this.shiftModel;
    }
    this.$spinner.removeSpinner();
  }

  private handleChange(event: any): void {
    this.editableShiftModel = {
      ...this.editableShiftModel,
      [event.target.name]: event.target.value,
    };
  }

  private async onSubmit() {
    this.$spinner.showSpinner();
    if (this.isCreateMode) {
      shiftModelsService
        .create(this.editableShiftModel)
        .then(() => this.$emit('close'))
        .finally(() => this.$spinner.removeSpinner());
    } else if (this.isEditMode) {
      shiftModelsService
        .update(this.editableShiftModel)
        .then(() => this.$emit('close'))
        .finally(() => this.$spinner.removeSpinner());
    }
  }

  private get isEnabled() {
    if (this.editableShiftModel && this.$refs.form) {
      const refForm = this.$refs.form as any;
      return refForm.checkValidity();
    }
    return false;
  }

  private get isEditMode(): boolean {
    return this.mode === Mode.Edit;
  }

  private get isCreateMode(): boolean {
    return this.mode === Mode.Create;
  }

  private get actionTitle() {
    if (this.isCreateMode) {
      return i18n.t('action.create').toString();
    } else if (this.isEditMode) {
      return i18n.t('action.save').toString();
    } else {
      return i18n.t('action.close').toString();
    }
  }
}
