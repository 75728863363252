
import { Wizard } from '@/components/wizard';
import { User } from '@/models/user';
import { Component, Prop, Vue } from 'vue-property-decorator';
import PersonalDataWizardStep from './steps/PersonalDataWizardStep.vue';
import SummaryWizardStep from './steps/SummaryWizardStep.vue';
import SubsidiaryMembershipWizardStep from './steps/SubsidiaryMembershipWizardStep.vue';
import { usersService, UserUpdate } from '@/services/users.service';
import { isNil } from '@/utils/misc';
import { subsidiariesService } from '@/services/subsidiaries.service';
import { currentUser } from '@/utils/permissionUtils';

@Component({
  components: {
    SubsidiaryMembershipWizardStep,
    PersonalDataWizardStep,
    SummaryWizardStep,
    Wizard,
  },
})
export default class SubsidiaryAdminWizard extends Vue {
  @Prop({ default: null })
  private user!: User | null;

  @Prop({ default: null })
  private subsidiaryId!: number | null;

  private userCopy: User | null = null;

  private async created() {
    this.userCopy = this.user ?? User.emptySubsidiaryAdmin();

    if (this.subsidiaryId !== null) {
      this.userCopy.subsidiaryId = this.subsidiaryId;
      this.userCopy.subsidiaryName = await subsidiariesService.getNameById(this.subsidiaryId);
    } else if (currentUser().isSubsidiaryAdmin) {
      this.userCopy.subsidiaryId = currentUser().subsidiaryId;
      this.userCopy.subsidiaryName = await subsidiariesService
        .getNameById(currentUser().subsidiaryId!);
    }
  }

  private get shouldHideMembershipStep() {
    return this.isHandlingUserFromSubsidiaryUserList
      || this.isUserEditingItself
      || currentUser().isSubsidiaryAdmin;
  }

  private get isHandlingUserFromSubsidiaryUserList() {
    return this.subsidiaryId !== null;
  }

  private get isUserEditingItself() {
    return usersService.store.current().id === this.user?.id;
  }

  private async submit() {
    this.$spinner.showSpinner();
    try {
      if (this.isEdit) {
        const updateUser: UserUpdate = User.GetCopy(this.userCopy!);
        updateUser.isAcceptedTermsOfAgreement = undefined;
        updateUser.isAutoplay = undefined;
        updateUser.autoplayPeriod = undefined;
        updateUser.dashboards = undefined;
        await usersService.update(updateUser);
      } else {
        await usersService.create(this.userCopy!);
      }
      this.$emit('close');
    } finally {
      this.$spinner.removeSpinner();
    }
  }

  private get isEdit(): boolean {
    return !!this.user;
  }
}
