
import { Component, Prop, Ref, Vue } from 'vue-property-decorator';
import { userTargetsService } from '@/services/userTargets.service';
import { Tenant } from '@/models/tenant';
import { UserTarget } from '@/models/userTarget';
import CreateTarget from '@/components/targets/CreateTarget.vue';
import UpdateTarget from '@/components/targets/UpdateTarget.vue';
import { ConsoleEnum } from '@/models/enums/ConsoleEnum';
import { TargetsMetric } from '@/models/enums/TargetsMetric';
import { isNil } from '@/utils/misc';
import TableActionButton from '../tableWithActions/TableActionButton.vue';
import TableWithActions from '../tableWithActions/TableWithActions.vue';

@Component({
  components: {
    'sc-create-target': CreateTarget,
    'sc-update-target': UpdateTarget,
    TableActionButton,
    TableWithActions,
  },
})
export default class Targets extends Vue {
  @Prop({ required: true })
  private console!: ConsoleEnum;

  @Prop({ required: true, default: undefined })
  private tenant!: Tenant;

  private userTargets: UserTarget[] = [];
  private showCreateOption = true;

  @Ref('updateTargetComponent')
  private updateTargetComponent!: UpdateTarget;

  private async created() {
    await this.getTargets();
  }

  private async getTargets() {
    this.$spinner.showSpinner();
    this.userTargets = [];
    try {
      await userTargetsService.getForTenant(this.tenant.id);
      this.userTargets = userTargetsService.store.getConsoleUserTargets(this.console);
    } finally {
      this.$spinner.removeSpinner();
    }
  }

  private confirmDelete(target: UserTarget): void {
    if (isNil(target.id)) {
      return;
    }

    this.updateTargetComponent.cancelUpdateTarget();

    const translatedTargetMetric = this.$t(`target.targetMetric.${target.targetMetric}`);
    const message = !isNil(target.target2)
      ? translatedTargetMetric + ' (' + target.target1 + ', ' + target.target2 + ')'
      : translatedTargetMetric + ' (' + target.target1 + ')';

    this.$buefy.dialog.confirm({
      title: this.$i18n.t('dialog.confirm_title').toString(),
      message: this.$i18n.t('action.delete').toString() + ` <b> ${message} </b>?`,
      confirmText: this.$i18n.t('dialog.confirm_delete').toString(),
      cancelText: this.$i18n.t(`dialog.confirm_cancel`).toString(),
      type: 'is-danger',
      hasIcon: true,
      onConfirm: () => this.deleteTarget(target.id!),
    });
  }

  private deleteTarget(targetId: number): void {
    this.$spinner.showSpinner();
    userTargetsService
      .delete(targetId)
      .then(() => {
        this.getTargets();
      })
      .finally(() => {
        this.$spinner.removeSpinner();
      });
  }

  private async updateTarget(target: UserTarget) {
    if (!!target.id) {
      this.updateTargetComponent.openTargetUpdateForm(target);
    }
  }

  private async onTargetCreated() {
    await this.getTargets();
  }

  private async onTargetUpdated() {
    await this.getTargets();
    this.showCreateOption = true;
  }

  private onUpdateStarted() {
    this.showCreateOption = false;
  }

  private onTargetUpdateCanceled() {
    this.showCreateOption = true;
  }

  private translateMetric(targetMetric: TargetsMetric) {
    return this.$i18n.t(`target.targetMetric.${targetMetric}`);
  }

  private get consoleName() {
    return this.$t(`category_menu.${ConsoleEnum[this.console]}`);
  }
}
