
import { Component, Prop, Vue } from 'vue-property-decorator';
import { subsidiariesService } from '@/services/subsidiaries.service';
import { Subsidiary } from '@/models/subsidiary';

@Component({})
export default class SubsidiaryProperties extends Vue {
  @Prop({ required: true, default: Subsidiary.GetEmpty() })
  public subsidiary!: Subsidiary;

  public editSubsidiary: Subsidiary = Subsidiary.GetEmpty();

  public created() {
    this.editSubsidiary = Subsidiary.GetCopy(this.subsidiary);
  }

  public handleChange(): void {
    this.editSubsidiary = Subsidiary.GetCopy(this.editSubsidiary);
  }

  public get isEnabled() {
    if (this.editSubsidiary && this.$refs.form) {
      const refForm = this.$refs.form as any;
      return refForm.checkValidity();
    }
    return false;
  }

  public async onSubmit() {
    this.$spinner.showSpinner();
    subsidiariesService.update(this.editSubsidiary).finally(() => this.$spinner.removeSpinner());
  }
}
