// --------------------------------------------------------------------------------
// <copyright file="measurement.ts" company="Bystronic Laser AG">
//  Copyright (C) Bystronic Laser AG 2021-2024
// </copyright>
// --------------------------------------------------------------------------------

import { Measurement } from '@/models/measurement';
import { mastersService } from '@/services/masters.service';
import { tenantsService } from '@/services/tenants.service';

export const measurementUnit: () => string = () => {
  const measurement = getTenantMeasurement();

  switch (measurement?.name) {
    case 'Metric':
      return 'kg';
    case 'Imperial':
      return 'lb';
    default:
      return '';
  }
};

function getTenantMeasurement(): Measurement | undefined {
  const tenantMeasurementId = getTenantMeasurementId();
  return getAvailableMeasurements()?.find((measurement) => tenantMeasurementId === measurement.id);
}

function getAvailableMeasurements(): Measurement[] {
  return mastersService.store.getMeasurementUnits();
}

export function getTenantMeasurementId(): number | null {
  return tenantsService.store.current()?.measurement ?? null;
}

export function getUnitTransform(convertToLbs: boolean): (x: number) => number {
  return convertToLbs ? (x: number) => 2.20462262185 * x : (x: number) => x;
}
