
import { Component, Prop, Provide, Vue } from 'vue-property-decorator';
import TableActionButton from './TableActionButton.vue';
import { VNode } from 'vue';
import { isNil } from '@/utils/misc';

type MenuPosition = 'is-top-right' | 'is-top-left' | 'is-bottom-left' | 'is-bottom-right';

@Component({})
export default class TableActions extends Vue {
  @Prop({ default: 'is-bottom-left' })
  private position!: MenuPosition;

  @Prop({ default: 'row' })
  private type!: 'selection' | 'row';

  @Prop({ default: 'dots-horizontal' })
  private icon!: string;

  private get unhiddenSlots(): VNode[] {
    const slots = this.$slots.default ?? [];
    return slots.filter(
      (slot) =>
        (slot.componentOptions?.propsData as TableActionButton | undefined)?.hidden !== true,
    );
  }

  private get hasMultipleActions(): boolean {
    return (
      this.unhiddenSlots
        // Slots removed with v-if still show up in the list,
        // but with undefined values on all fields
        .filter((slot) => !isNil(slot.tag)).length > 1
    );
  }

  @Provide()
  private get shouldUseMenu(): boolean {
    return this.hasMultipleActions || this.type === 'selection';
  }

  // This event is only emitted as a workaround to click propagation issue with
  // row action menus. It doesn't have any other use.
  private emitWorkaroundEvent(event: any) {
    this.$emit('row-menu-active-change', event);
  }
}
