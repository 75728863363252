
import { Component, Prop, Vue } from 'vue-property-decorator';
import { locationsService } from '@/services/locations.service';
import { Location } from '@/models/location';
import TenantsSelect from '@/components/inputs/TenantsSelect.vue';

@Component({
  components: {
    'sc-tenants-select': TenantsSelect,
  },
})
export default class CreateLocation extends Vue {
  @Prop({ required: true })
  public tenantId!: number;

  public location: Location = Location.GetEmpty();

  private mounted() {
    this.location.tenantId = this.tenantId;
  }

  public async onSubmit() {
    this.$spinner.showSpinner();
    locationsService
      .create(this.location)
      .then(() => this.$emit('close'))
      .finally(() => this.$spinner.removeSpinner());
  }

  public get isEnabled() {
    if (this.location && this.$refs.form) {
      const refForm = this.$refs.form as any;
      return refForm.checkValidity();
    }
    return false;
  }

  public handleChange(event: any): void {
    this.location = Location.GetCopy(this.location);
  }
}
