
import { Component, Prop, Vue } from 'vue-property-decorator';
import { copyToClipboard } from '@/utils/clipboard';

@Component({})
export default class ConnectionStringModal extends Vue {
  @Prop({ default: false })
  private loading!: boolean;
  @Prop({ required: true })
  private title!: string;
  @Prop()
  private value: string | null = null;

  private copyKey() {
    try {
      copyToClipboard(this.value!);
      this.$buefy.toast.open(this.$i18n.t('action.clipboard').toString());
    } catch (e) {
      this.$buefy.toast.open({
        message: `${e}`,
        type: 'is-danger',
      });
    }
  }
}
