
import { Component, Prop, Ref, Vue } from 'vue-property-decorator';
import { usersService } from '@/services/users.service';
import { ValidationObserver, ValidationProvider } from 'vee-validate';

@Component({
  components: { ValidationProvider, ValidationObserver },
})
export default class ResetPassword extends Vue {
  @Prop()
  private id!: number;

  @Prop()
  private email!: string;

  @Ref('validation-observer')
  private validationObserver!: any;

  private password: string = '';
  private password2: string = '';

  private handleChange() {
    this.password = '' + this.password;
  }

  isValid(): boolean {
    return this.validationObserver.flags.valid;
  }

  private get isEnabled() {
    if (this.password && this.password2 && this.$refs.form && this.isValid()) {
      const refForm = this.$refs.form as any;
      return refForm.checkValidity();
    }
    return false;
  }

  private async onSubmit() {
    this.$spinner.showSpinner();
    usersService
      .changePassword(this.id, this.email, this.password)
      .then(() => this.closeModal())
      .finally(() => this.$spinner.removeSpinner());
  }

  private closeModal() {
    this.$emit('close');
  }

  private get validationRules() {
    return {
      password: {
        required: true,
        max: 256,
        password_repeat: [this.password2],
      },
    };
  }
}
