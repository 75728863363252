
import { Wizard } from '@/components/wizard';
import { Component, Prop, Vue } from 'vue-property-decorator';
import PersonalDataWizardStep from './steps/PersonalDataWizardStep.vue';
import { User } from '@/models/user';
import SummaryWizardStep from './steps/SummaryWizardStep.vue';
import { usersService } from '@/services/users.service';

@Component({
  components: {
    PersonalDataWizardStep,
    SummaryWizardStep,
    Wizard,
  },
})
export default class ServiceGlobalWizard extends Vue {
  @Prop({ default: null })
  private user!: User | null;

  private userCopy: User | null = null;

  private created() {
    this.userCopy = this.user ?? User.emptyServiceGlobal();
  }

  private async submit() {
    this.$spinner.showSpinner();
    try {
      if (this.isEdit) {
        // HACK: This is to avoid validation issues in the backend.
        // To be fixed as part of #123060.
        this.userCopy!.isAutoplay = undefined as any;
        this.userCopy!.isAcceptedTermsOfAgreement = undefined as any;
        this.userCopy!.autoplayPeriod = undefined as any;
        this.userCopy!.dashboards = undefined as any;

        await usersService.update(this.userCopy!);
      } else {
        await usersService.create(this.userCopy!);
      }
      this.$emit('close');
    } finally {
      this.$spinner.removeSpinner();
    }
  }

  private get isEdit(): boolean {
    return !!this.user;
  }
}
