// --------------------------------------------------------------------------------
// <copyright file="temperaturesChartTooltipFormatter.ts" company="Bystronic Laser AG">
//  Copyright (C) Bystronic Laser AG 2021-2024
// </copyright>
// --------------------------------------------------------------------------------

import i18n from '@/i18n';
import { TemperaturesData } from './chartsData';
import { decimalFormatter } from '@/utils/number';
import { FilterTimeAxisSpanEnum } from '../enums/FilterTimeAxisSpanEnum';
import { getDateTooltipLabel } from '@/utils/charts';
import { isNil } from '@/utils/misc';
import {
  getChartTitleTooltipHtml,
  getHeadVersionChangeDataHtml,
  isHeadVersionChangeTooltipParamItem,
} from './cuttingHeadCommons';

export function tooltipFormatter(
  params: any,
  data: TemperaturesData[],
  dateGrouping: FilterTimeAxisSpanEnum,
): string {
  let absoluteTemperatureChartHtml = '';
  let temperatureDifferenceChartHtml = '';
  let cuttingHeadVersionChangeHtml = '';

  params.forEach((paramItem: any) => {
    if (isAbsoluteTemperatureTooltipParamItem(paramItem)) {
      absoluteTemperatureChartHtml += getNumericValueTooltipHtml(paramItem);
    } else if (isTemperatureDifferenceTooltipParamItem(paramItem)) {
      temperatureDifferenceChartHtml += getNumericValueTooltipHtml(paramItem);
    } else if (isHeadVersionChangeTooltipParamItem(paramItem)) {
      cuttingHeadVersionChangeHtml += getHeadVersionChangeDataHtml(paramItem, data, dateGrouping);
    }
  });

  return `
    <div class="tooltip-title" data-testid="title">
      ${getDateTooltipLabel(params[0].axisValueLabel, dateGrouping)}
    </div>
    
    ${getChartTitleTooltipHtml('absolute_temperature')}
    ${absoluteTemperatureChartHtml}
    
    ${getChartTitleTooltipHtml('temperature_difference')}
    ${temperatureDifferenceChartHtml}
    ${cuttingHeadVersionChangeHtml}
  `;
}

function isAbsoluteTemperatureTooltipParamItem(paramItem: any) {
  return paramItem.seriesType === 'line';
}

function isTemperatureDifferenceTooltipParamItem(paramItem: any) {
  return paramItem.seriesType === 'bar';
}

function getNumericValueTooltipHtml(seriesParam: any): string {
  // ['ISO date', value] or value depending on the date grouping
  const value = Array.isArray(seriesParam.value) ? seriesParam.value?.[1] : seriesParam.value;
  const unit = i18n.t('celsius_degrees_abbreviation');
  const formattedValue = isNil(value) ? `- ${unit}` : `${decimalFormatter(value)} ${unit}`;

  return getValueTooltipHtml(seriesParam, formattedValue);
}

function getValueTooltipHtml(seriesParam: any, value: string): string {
  return `
    <div class="tooltip-series-value-row" data-testid="series-value-${seriesParam.seriesName}">
      <div class="series-marker-name">
        ${seriesParam.marker}
        ${i18n.t(`report.${seriesParam.seriesName}`)}
      </div>
      <div class="series-value">
        ${value}
      </div>
    </div>
  `;
}
