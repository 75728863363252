// --------------------------------------------------------------------------------
// <copyright file="logger.ts" company="Bystronic Laser AG">
//  Copyright (C) Bystronic Laser AG 2021-2024
// </copyright>
// --------------------------------------------------------------------------------
/* eslint-disable no-console */

export class Logger {
  public static error(message?: any, ...optionalParams: any[]) {
    if (process.env.NODE_ENV !== 'production') {
      console.error(message, ...optionalParams);
    }
  }

  public static warn(message?: any, ...optionalParams: any[]) {
    if (process.env.NODE_ENV !== 'production') {
      console.warn(message, ...optionalParams);
    }
  }

  public static info(message?: any, ...optionalParams: any[]) {
    if (process.env.NODE_ENV !== 'production') {
      console.info(message, ...optionalParams);
    }
  }
}
