
import { AlertKPI, AlertKPIGroup } from '@/models/enums/AlertsEnums';
import { PersistentGetters } from '@/store/persistent/enums';
import { Component, Vue, Prop } from 'vue-property-decorator';

@Component
export default class AlertsMetricsSelect extends Vue {
  @Prop({ default: '' })
  public value!: string;

  @Prop({ default: false })
  public required!: boolean;

  @Prop({ default: false })
  public disabled!: boolean;

  @Prop({ default: false })
  public flatten!: boolean;

  public groups: AlertKPIGroup[] = [];

  public mounted(): void {
    this.getItems();
  }
  public async getItems() {
    this.$spinner.showSpinner();

    this.groups = this.$store.getters[PersistentGetters.GetKPIGroups];

    this.$spinner.removeSpinner();
  }

  public handleSelect(value: AlertKPI) {
    this.$emit('input', value);
    this.$emit('change', value);
  }
}
