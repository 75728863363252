// --------------------------------------------------------------------------------
// <copyright file="timeBetweenBendKpiWidgetGenerator.ts" company="Bystronic Laser AG">
//  Copyright (C) Bystronic Laser AG 2021-2024
// </copyright>
// --------------------------------------------------------------------------------

import { FilterTimeSpanEnum } from '../enums/FilterTimeSpanEnum';
import { ProcedureName } from './abstract/chartGenerator';
import { metricsService } from '@/services/metrics.service';
import { DataWithTimeSpan } from './chartsData';
import { devicesService } from '@/services/devices.service';
import { PieChartKpiWidgetGenerator } from './abstract/pieChartKpiWidgetGenerator';

export interface BendingTimeBetweenBendDataPerDevice extends DataWithTimeSpan {
  deviceId: string;
  categoryName: 'complex' | 'medium' | 'simple';
  timeBetweenBend: number;
}

export class TimeBetweenBendKpiWidgetGenerator extends PieChartKpiWidgetGenerator<BendingTimeBetweenBendDataPerDevice> {
  constructor(procedure: ProcedureName, tenantIdDh: number) {
    const mainColor = '#28c76f';
    const unit = 's';
    super(procedure, tenantIdDh, mainColor, unit);
  }

  override async getData(
    selectedDevices: string[],
    selectedShifts: number[],
    timeSpan: FilterTimeSpanEnum | [string, string],
  ): Promise<BendingTimeBetweenBendDataPerDevice[] | null> {
    return (
      await metricsService.getDevicesMetrics<BendingTimeBetweenBendDataPerDevice[]>(
        this.procedure,
        {
          tenantIdDh: this.tenantIdDh,
          deviceIds: selectedDevices,
          shifts: selectedShifts,
          timeSpan: timeSpan as FilterTimeSpanEnum,
        },
        this.controller,
      )
    ).filter((x) => x.categoryName === 'medium');
  }

  protected override getMaxDataEntry(primaryData: BendingTimeBetweenBendDataPerDevice[]): number {
    return Math.max(...primaryData.map((x) => x.timeBetweenBend));
  }

  protected override getSeriesData(primaryData: BendingTimeBetweenBendDataPerDevice[]): any[] {
    return primaryData.map((x) => ({
      name: devicesService.store.findByStringId(x.deviceId)!.name,
      value: x.timeBetweenBend,
    }));
  }
}
