
import { Device } from '@/models/device';
import { DeviceStateData } from '@/models/deviceStateData';
import { Component, Prop, Vue } from 'vue-property-decorator';
import DeviceImage from '../devices/DeviceImage.vue';
import { getStateCss } from '@/utils/color';
import moment from 'moment';
import { isEmpty, isNil } from '@/utils/misc';
import { abbreviateNumber, formatPercentage } from '@/utils/number';
import {
  CutCurrentWorkloadData,
  CutCurrentWorkloadDataPlanInfo,
  MessageLevel,
} from '@/models/Charts/chartsData';
import { tenantsService } from '@/services/tenants.service';
import { Tenant } from '@/models/tenant';
import ActionBubble, { BubbleType } from '../ActionBubble.vue';
import CutCellControlActiveMessages from '../cut/CutCellControlActiveMessages.vue';
import { modalService } from '@/library-services/modal.service';
import { metricsService } from '@/services/metrics.service';
import { currentUser } from '@/utils/permissionUtils';
import { BetaFeature } from '@/models/enums/BetaFeature';

@Component({
  components: {
    ActionBubble,
    DeviceImage,
  },
  methods: {
    abbreviateNumber,
    formatPercentage,
    isEmpty,
    isNil,
  },
})
export default class OverviewDeviceCard extends Vue {
  @Prop({ required: true })
  private device!: Device;

  @Prop({ required: true })
  private locationName!: string;

  @Prop({ required: true })
  private now!: Date;

  @Prop()
  private deviceState?: DeviceStateData;

  @Prop({ default: false })
  private loading!: boolean;

  @Prop({ default: true })
  private isFirstLoad!: boolean;

  @Prop({ default: false })
  private compactMode!: boolean;

  private workloadUpdateHandle = -1;
  private workloadData = this.emptyData;
  private workloadLoading = false;

  private controller = new AbortController();

  private mounted() {
    if (this.device.isCut()) {
      this.getWorkloadData();

      if (this.workloadUpdateHandle !== -1) {
        this.workloadUpdateHandle = window.setInterval(this.getWorkloadData, 30 * 1000);
      }
    }
  }

  private beforeDestroy() {
    window.clearInterval(this.workloadUpdateHandle);
    this.controller.abort();
  }

  private get tenant(): Tenant | null {
    return tenantsService.store.current();
  }

  private get emptyData(): CutCurrentWorkloadData {
    return {
      currentMessages: [],
      cuttingPlanInfo: [],
    };
  }

  private get hasState(): boolean {
    return !!this.deviceState?.last_state;
  }

  private get hasCuttingPlans(): boolean {
    return this.device.isCut() && !isEmpty(this.workloadData.cuttingPlanInfo);
  }

  private get currentPlan(): CutCurrentWorkloadDataPlanInfo {
    // TODO: If there's more than one plan, which one do I pick?
    return this.workloadData.cuttingPlanInfo[0];
  }

  private get navigationButtonIcon(): string {
    if (this.device.isCut()) {
      return 'cutting-color';
    }
    if (this.device.isBend()) {
      return 'bending-color';
    }
    if (this.device.isTube()) {
      return 'tube-color';
    }
    return '';
  }

  private get bubbleLabel(): string | undefined {
    if (isEmpty(this.workloadData.currentMessages)) {
      return undefined;
    }
    return this.workloadData.currentMessages.length.toString();
  }

  private get bubbleType(): BubbleType | undefined {
    if (isEmpty(this.workloadData.currentMessages)) {
      return undefined;
    }
    if (this.workloadData.currentMessages.some((x) => x.messageLevel === MessageLevel.Error)) {
      return 'is-danger';
    }
    if (this.workloadData.currentMessages.some((x) => x.messageLevel === MessageLevel.Warning)) {
      return 'is-warning';
    }
    return 'is-info';
  }

  private stateStyle() {
    return getStateCss(this.deviceState!.last_state);
  }

  private getDeviceMessage(): string {
    const lastSeenUtc = this.deviceState!.last_ts_utc;
    const lastSeen = moment(`${lastSeenUtc}+00:00`);
    const now = moment(this.now);
    if (now.diff(lastSeen, 'hours') > 0) {
      return this.$tc('time_msg.hours', moment().diff(lastSeen, 'hours')).toString();
    } else if (now.diff(lastSeen, 'minutes') > 0) {
      return this.$tc('time_msg.minutes', moment().diff(lastSeen, 'minutes')).toString();
    } else {
      return this.$tc('time_msg.seconds', moment().diff(lastSeen, 'seconds')).toString();
    }
  }

  private async getWorkloadData() {
    try {
      this.controller.abort();
      this.controller = new AbortController();
      this.workloadLoading = true;
      this.workloadData = await metricsService.getDevicesCurrentWorkload(
        this.device.deviceId,
        this.tenant!.tenantIdDh,
        this.controller,
      );
    } finally {
      this.workloadLoading = false;
    }
  }

  private openMessageModal() {
    modalService.open({
      parent: this,
      component: CutCellControlActiveMessages,
      props: {
        messages: this.workloadData.currentMessages,
      },
    });
  }

  private get hasCurrentWorkloadBetaFeature() {
    return currentUser().hasBetaFeature(BetaFeature.WorkCenterConsoleCutCurrentWorkload);
  }

  private get showWorkload() {
    return this.hasCurrentWorkloadBetaFeature && (this.hasCuttingPlans || this.workloadLoading);
  }
}
