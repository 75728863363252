// --------------------------------------------------------------------------------
// <copyright file="mutations.ts" company="Bystronic Laser AG">
//  Copyright (C) Bystronic Laser AG 2021-2024
// </copyright>
// --------------------------------------------------------------------------------

import { CurrencyCode } from '@/models/currencyCode';
import { Measurement } from '@/models/measurement';
import { TimeZone } from '@/models/timeZone';
import { MutationTree } from 'vuex';
import PersistentState from './persistentState';
import { DeviceBaseModel } from '@/models/deviceBaseModel';
import { User } from '@/models/user';
import { UserAlertStatus } from '@/models/userAlertStatus';
import { Tenant } from '@/models/tenant';
import { Device } from '@/models/device';
import { Shift } from '@/models/shift';
import { Target } from '@/models/target';
import moment from 'moment';
import { AlertKPIGroup } from '@/models/enums/AlertsEnums';
import { UserTarget } from '@/models/userTarget';
import state from './state';

const mutations: MutationTree<PersistentState> = {
  setLocale(state: PersistentState, locale: string) {
    state.locale = locale;
    moment.locale(locale);
  },
  setTimezones(state: PersistentState, timezones: TimeZone[]) {
    state.timezones = timezones;
  },
  setCurrencyCodes(state: PersistentState, currencyCodes: CurrencyCode[]) {
    state.currencyCodes = currencyCodes;
  },
  setMeasurements(state: PersistentState, measurements: Measurement[]) {
    state.measurements = measurements;
  },
  setDeviceBaseModels(state: PersistentState, deviceBaseModels: DeviceBaseModel[]) {
    state.deviceBaseModels = deviceBaseModels;
  },
  setCurrentUser(state: PersistentState, value: User) {
    state.currentUser = User.GetCopy(value);
  },
  setNotifications(state: PersistentState, value: UserAlertStatus[]) {
    state.notifications = value;
  },
  setTenant(state: PersistentState, value: Tenant) {
    state.tenant = value;
  },
  setDevices(state: PersistentState, value: Device[]) {
    state.devices = value;
  },
  setShifts(state: PersistentState, value: Shift[]) {
    state.shifts = value;
  },
  setTargets(state: PersistentState, value: Target[]) {
    state.targets = value;
  },
  setUserTargets(state: PersistentState, userTargets: UserTarget[]) {
    state.userTargets = userTargets;
  },
  setKpiGroups(state: PersistentState, value: AlertKPIGroup[]) {
    state.kpiGroups = value;
  },
  setEventModules(state: PersistentState, value: string[]) {
    state.eventModules = value;
  },
  setEventCodes(state: PersistentState, value: string[]) {
    state.eventCodes = value;
  },
  resetState(currentState: PersistentState) {
    const initialState = state;
    function resetValue<K extends keyof PersistentState>(key: K) {
      currentState[key] = initialState[key];
    }
    (Object.keys(initialState) as Array<keyof PersistentState>).forEach((key) => {
      resetValue(key);
    });
  },
};

export default mutations;
