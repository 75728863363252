
import { Component, ProvideReactive, Vue, Watch } from 'vue-property-decorator';
import { Logger } from '@/utils/logger';
import { ImportantMessageLevel } from '@/models/Charts/chartsData';
import { FilterTimeAxisSpanEnum } from '@/models/enums/FilterTimeAxisSpanEnum';
import EventTypeFilter from '@/components/EventTypeFilter.vue';
import DateGroupingFilter from '@/components/DateGroupingFilter.vue';
import { tenantsService } from '@/services/tenants.service';
import { devicesService } from '@/services/devices.service';
import { Device } from '@/models/device';
import { isEmpty, isNil } from '@/utils/misc';
import { Route } from 'vue-router';
import EventModuleFilter from '@/components/EventModuleFilter.vue';
import { Tenant } from '@/models/tenant';
import IconSwitch from '@/components/switch/IconSwitch.vue';
import CutLayout from '@/components/common/CutLayout.vue';
import { FilterTimeSpanEnum } from '@/models/enums/FilterTimeSpanEnum';
import { CareTab, DeviceView, isValidCareTab } from './commons/navigation';
import CareConsoleMainView from './CareConsoleMainView.vue';
import { MachineType } from '@/models/enums/MachineType';
import TimeSpanFilter from '@/components/TimeSpanFilter.vue';
import ShiftFilter from '@/components/ShiftFilter.vue';
import { shiftsService } from '@/services/shifts.service';
import { userTargetsService } from '@/services/userTargets.service';
import { router } from '@/library-services/e-service-center-router';
import EventCodesFilter from '@/components/EventCodesFilter.vue';
import { CutLayoutTab } from '@/components/common/CutLayoutTab';
import { toastService } from '@/library-services/toast.service';
import MultipleSelectionFiltersHeader from '@/components/MultipleSelectionFiltersHeader.vue';
import { Shift } from '@/models/shift';
import DeviceViewSwitch from '@/components/DeviceViewSwitch.vue';
import { getActiveShifts } from '@/utils/page-filters';
import EquipmentFilter from '@/components/EquipmentFilter.vue';
import { defaultDateRange } from './commons/utils';

@Component({
  computed: {
    Shift: () => Shift,
    MachineType: () => MachineType,
  },
  methods: { isNil, isEmpty },
  components: {
    DeviceViewSwitch,
    MultipleSelectionFiltersHeader,
    DateGroupingFilter,
    EventModuleFilter,
    EventTypeFilter,
    EventCodesFilter,
    IconSwitch,
    CutLayout,
    CareConsoleMainView,
    TimeSpanFilter,
    ShiftFilter,
    EquipmentFilter,
  },
})
export default class CareConsoleDevice extends Vue {
  private activeCareTab = CareTab.Overview;
  private activeWorkCenterTab = CutLayoutTab.CutOverview;
  private activeView = DeviceView.Care;
  private devices: Device[] = [];

  private dateRangeSelection = defaultDateRange();
  private selectedTimeAxisSpan = FilterTimeAxisSpanEnum.Day;
  private selectedEventTypes = [ImportantMessageLevel.Error];
  private selectedEventModules: string[] = [];
  private selectedEventCodes: string[] = [];
  private selectedTimespan = FilterTimeSpanEnum.Day;
  private selectedShiftIds: number[] = [];

  // Needed by Chart components.
  @ProvideReactive('supportTenantIdDh')
  private supportTenantIdDh!: string;

  private customerTimeZone: string | null = null;
  private customer: Tenant | null = null;

  // created instead of mounted to avoid loading the default Alerts tab and the
  // URL-selected one. We need to have the correct value in activeCareTab before
  // the first render.
  private async created() {
    await this.updateStateFromRoute(this.$route);

    try {
      await this.fetchCustomerData();
    } catch (error) {
      Logger.error(error);
      await router.goToHome();
    }
  }

  @Watch('supportTenantIdDh')
  private async fetchCustomerData() {
    this.customer = await tenantsService.getByIdDh(+this.$route.params.customerIdDh);
    this.customerTimeZone = this.customer.timeZone;
    this.devices = await devicesService.get(this.customer.id);

    if (isEmpty(this.devices)) {
      throw Error(`No devices found for customer ${this.$route.params.customerIdDh}`);
    }

    await this.throwIfEquipmentIdNotFound();
    await tenantsService.store.update(this.customer);
    await devicesService.store.updateAll(this.devices);
    await userTargetsService.getForTenant(this.customer!.id);
    await shiftsService.getByTenantId(this.customer!.id);
  }

  private async beforeRouteUpdate(to: Route, from: Route, next: () => void) {
    await this.updateStateFromRoute(to);
    next();
  }

  private async updateStateFromRoute(route: Route) {
    this.supportTenantIdDh = route.params.customerIdDh;

    const hash = route.hash.substring(1);
    if (hash === '') {
      await this.$router.replace({
        hash: CareTab[this.activeCareTab],
      });
    } else if (this.isWorkCenterViewRoute(route)) {
      this.activeView = DeviceView.WorkCenter;
      this.activeWorkCenterTab = CutLayoutTab[hash as keyof typeof CutLayoutTab];
    } else if (hash !== CareTab[this.activeCareTab] && isValidCareTab(hash)) {
      this.activeCareTab = CareTab[hash];
    }
  }

  private async onDeviceChanged(id: string) {
    await router.setCareConsoleDevice(id, this.$route.hash);
  }

  @Watch('activeView')
  private async onActiveViewChanged() {
    if (this.isCareViewActive) {
      await this.updateRouteHashWithCareTabValue();
    } else {
      if (!this.isWorkCenterViewRoute(this.$route)) {
        await this.$router.replace({
          hash: CutLayoutTab[this.activeWorkCenterTab],
        });
      }
    }
  }

  @Watch('activeWorkCenterTab')
  private async updateRouteHashWithWorkCenterTabValue() {
    // Avoid redundant navigation error on page reload
    if (this.$route.hash.substring(1) !== CutLayoutTab[this.activeWorkCenterTab]) {
      await this.$router.replace({
        hash: CutLayoutTab[this.activeWorkCenterTab],
      });
    }
  }

  @Watch('activeCareTab')
  private async updateRouteHashWithCareTabValue() {
    // Avoid redundant navigation error on page reload
    if (this.$route.hash.substring(1) !== CareTab[this.activeCareTab]) {
      await this.$router.replace({
        hash: CareTab[this.activeCareTab],
      });
    }
  }

  private async beforeDestroy() {
    await tenantsService.store.update(null);
    await devicesService.store.updateAll(this.devices);
    await userTargetsService.store.clear();
    await shiftsService.store.clear();
  }

  private toggleDatepicker() {
    (this.$refs.desktopDatePicker as any)?.toggle?.();
  }

  private get selectedDeviceId(): string {
    return this.$route.params.equipmentId;
  }

  private get selectedDevicesForShiftFilter(): [string] {
    return [this.selectedDeviceId];
  }

  private get isCareViewActive(): boolean {
    return this.activeView === DeviceView.Care;
  }

  private get isTrendsTabActive(): boolean {
    return !this.isCareViewActive && this.activeWorkCenterTab === CutLayoutTab.CutTrends;
  }

  private get activeShifts(): Shift[] | undefined {
    if (!this.hasShiftFilter) {
      return undefined;
    }

    return getActiveShifts(this.selectedShiftIds, [this.selectedDeviceId], this.devices);
  }

  private get selectedEquipmentOrNull(): Device | null {
    return this.devices.find((device) => device.deviceId === this.selectedDeviceId) ?? null;
  }

  private async throwIfEquipmentIdNotFound() {
    if (isNil(this.selectedEquipmentOrNull)) {
      toastService.error('e_service_center.route_equipment_id_not_found', {
        equipmentId: this.$route.params.equipmentId,
      });
      throw Error(
        `Route equipment id '${this.$route.params.equipmentId}' not found in customer's equipment. Redirecting to home...`,
      );
    }
  }

  private get hasDateGroupingFilter() {
    if (this.isCareViewActive) {
      return ![CareTab.Alerts, CareTab.Overview].includes(this.activeCareTab);
    }
    return this.isTrendsTabActive;
  }

  private get hasEventTypesFilter() {
    return this.isCareViewActive && this.activeCareTab === CareTab.Events;
  }

  private get hasEventModuleFilter() {
    return this.isCareViewActive && this.activeCareTab === CareTab.Events;
  }

  private get hasEventCodesFilter() {
    return this.isCareViewActive && this.activeCareTab === CareTab.Events;
  }

  private get hasTimeSpanFilter(): boolean {
    return !this.isCareViewActive && this.activeWorkCenterTab === CutLayoutTab.CutOverview;
  }

  private get hasShiftFilter(): boolean {
    return !this.isCareViewActive && this.activeWorkCenterTab !== CutLayoutTab.CutCurrentWorkload;
  }

  private get hasDateRangeFilter() {
    if (this.isCareViewActive) {
      return true;
    }
    return this.isTrendsTabActive;
  }

  private isWorkCenterViewRoute(route: Route): boolean {
    return (
      route.hash.substring(1).startsWith('Cut') &&
      route.hash.substring(1) !== CareTab[CareTab.CuttingHead]
    );
  }
}
