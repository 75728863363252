
import { Component, Emit, Prop, Vue } from 'vue-property-decorator';
import DevicesSelect from '@/components/inputs/DevicesSelect.vue';
import EmailsSelect from '@/components/inputs/EmailsSelect.vue';
import { Alert } from '@/models/alert';
import AlertsMetricsSelect from '../inputs/AlertsMetricsSelect.vue';
import AlertsOperatorsSelect from '../inputs/AlertsOperatorsSelect.vue';
import AlertsActionsSelect from '../inputs/AlertsActionsSelect.vue';
import { alertsService } from '@/services/alerts.service';
import { Tenant } from '@/models/tenant';
import { AlertAction, AlertKPI } from '@/models/enums/AlertsEnums';
import { isEmpty } from '@/utils/misc';
import { DeviceModelType } from '@/models/deviceBaseModel';

@Component({
  computed: {
    AlertAction: () => AlertAction,
  },
  components: {
    'sc-devices-select': DevicesSelect,
    'sc-emails-select': EmailsSelect,
    'sc-alert-metrics-select': AlertsMetricsSelect,
    'sc-alert-operators-select': AlertsOperatorsSelect,
    'sc-alert-actions-select': AlertsActionsSelect,
  },
})
export default class EditAlert extends Vue {
  @Prop()
  private tenant!: Tenant;

  @Prop()
  private alert!: Alert;

  private editAlert: Alert | null = null;
  private thresholdValid: boolean = true;
  private defaultEmails: string[] = [];
  private selectedKpi: AlertKPI | null = null;
  private readonly deviceTypeLaser = DeviceModelType.Laser;

  private async mounted() {
    this.defaultEmails = await alertsService.getAlertEmail(this.alert.id!);
    this.editAlert = Alert.GetCopy(this.alert);

    if (!this.editAlert.deviceId) {
      this.editAlert.deviceId = 0;
    }
    this.selectedKpi = await alertsService.getAlertKpiByMetric(this.editAlert!.metric);
    this.thresholdValid = true;
  }

  private get isEnabled() {
    if (this.editAlert && this.$refs.form) {
      const refForm = this.$refs.form as any;
      return refForm.checkValidity() && this.thresholdValid && this.isEmailValid();
    }
    return false;
  }

  private handleChange(event: any): void {
    this.editAlert = { ...this.editAlert!, [event.target.name]: event.target.value };
  }

  private checkValidity(input: string) {
    const thresholdValue: number = +input;
    this.thresholdValid = !isNaN(thresholdValue) && input !== '';
  }

  private onEmailSelectionChanged(namesAndIds: Array<{ name: string; id: number }>) {
    this.editAlert = {
      ...this.editAlert!,
      recipients: namesAndIds.map((item) => item.id),
    };
  }

  private onSubmit() {
    this.$spinner.showSpinner();
    const alert = this.editAlert!;
    alert.tenantId = this.tenant.id;
    alert.metric = this.selectedKpi!.kpi;
    if (alert.deviceId === 0) {
      alert.deviceId = null;
    }
    alertsService
      .update(alert)
      .then(() => {
        this.save();
      })
      .finally(() => this.$spinner.removeSpinner());
  }

  @Emit('save')
  private save() {
    this.$emit('close');
  }

  private isEmailValid(): boolean {
    const alertAction = this.editAlert?.action;
    // FIXME: We get a string instead of AlertAction from AlertsActionsSelect
    return (
      // eslint-disable-next-line
      alertAction == AlertAction.Web ||
      // eslint-disable-next-line
      (alertAction == AlertAction.WebAndEmail && !isEmpty(this.editAlert!.recipients))
    );
  }
}
