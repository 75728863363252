// --------------------------------------------------------------------------------
// <copyright file="configuration.service.ts" company="Bystronic Laser AG">
//  Copyright (C) Bystronic Laser AG 2021-2024
// </copyright>
// --------------------------------------------------------------------------------

import axios from 'axios';
import { BaseUrl } from '@/models/constants';
import { ConfigurationSpa } from '@/models/configurationSpa';

class ConfigurationService {
  getConfig(): Promise<ConfigurationSpa> {
    return axios.get<ConfigurationSpa>(`${BaseUrl}/spa-configuration`).then((response: any) => {
      return response.data;
    });
  }
}

export const configurationService = new ConfigurationService();
