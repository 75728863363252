// --------------------------------------------------------------------------------
// <copyright file="userTarget.ts" company="Bystronic Laser AG">
//  Copyright (C) Bystronic Laser AG 2021-2024
// </copyright>
// --------------------------------------------------------------------------------

import { ConsoleEnum } from './enums/ConsoleEnum';
import { TargetsMetric } from './enums/TargetsMetric';
import { Target } from './target';

export class UserTarget {
  static GetEmpty(): UserTarget {
    return new UserTarget(null, null, 0, null, TargetsMetric.None);
  }
  constructor(
    public id: number | null,
    public tenantId: number | null,
    public target1: number,
    public target2: number | null,
    public targetMetric: TargetsMetric,
  ) {}
}

export class CreateUserTarget {
  static GetEmpty(): CreateUserTarget {
    return new CreateUserTarget(null, TargetsMetric.None, null, null);
  }
  constructor(
    public tenantId: number | null,
    public targetMetric: TargetsMetric,
    public target1: number | null,
    public target2?: number | null,
  ) {}
}
