
import { Component, Vue, Prop } from 'vue-property-decorator';
import { Tenant } from '@/models/tenant';
import { RestrictedTenant, tenantsService } from '@/services/tenants.service';
import { ValidationProvider } from 'vee-validate';
import { isNil } from '@/utils/misc';

@Component({
  components: {
    ValidationProvider,
  },
})
export default class TenantsSelect extends Vue {
  @Prop()
  public value?: number;

  @Prop({ default: undefined })
  public rules?: any;

  /** @deprecated Use rules with value 'required' or { required: true } instead. */
  @Prop({ default: false, type: Boolean })
  public required!: boolean;

  @Prop({ default: false, type: Boolean })
  public disabled!: boolean;

  @Prop({ default: true, type: Boolean })
  public showLabel!: boolean;

  @Prop({ default: 'inputs.customers_select.label' })
  private validationProviderName!: string;

  @Prop({ default: false, type: Boolean })
  private slim!: boolean;

  @Prop({ default: false, type: Boolean })
  private restricted!: boolean;

  private tenants: Array<Tenant | RestrictedTenant> = [];

  private mounted(): void {
    this.fetchTenants();
  }

  private async fetchTenants() {
    this.$spinner.showSpinner();
    try {
      if (this.restricted) {
        this.tenants = await tenantsService.getRestricted();
      } else {
        this.tenants = await tenantsService.get();
      }
      if (!isNil(this.value) && this.value > 0) {
        this.$emit('input:name', this.getTenantById(this.value)!.name);
      }
    } finally {
      this.$spinner.removeSpinner();
    }
  }

  private getTenantById(id: number): Tenant | RestrictedTenant | undefined {
    return this.tenants.find((x) => x.id === id);
  }

  private handleSelect(tenantId: number) {
    const tenant = this.getTenantById(tenantId)!;
    this.$emit('input:name', tenant.name);
    this.$emit('input', tenantId);
  }

  private get isRequired(): boolean {
    return this.required || this.rules?.required || this.rules?.includes?.('required');
  }
}
