
import { WizardStep } from '@/components/wizard';
import { ConsoleEnum } from '@/models/enums/ConsoleEnum';
import { User } from '@/models/user';
import { lastDayOfNextMonth } from '@/utils/dates';
import { isEmpty, isNil } from '@/utils/misc';
import moment from 'moment';
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({
  components: {
    WizardStep,
  },
  methods: {
    isEmpty,
  },
})
export default class ConsolesWizardStep extends Vue {
  @Prop({ required: true })
  private value!: User;

  @Prop({ required: true })
  private authorizedConsoles!: ConsoleEnum[];

  @Prop({ default: false, type: Boolean })
  private hidden!: boolean;

  private getConsoleActivationDate(console: ConsoleEnum): Date | null {
    return this.value.getConsoleActivationDate(console);
  }

  private getConsoleEndDate(console: ConsoleEnum): Date | null {
    return this.value.getConsoleEndDate(console);
  }

  private getConsoleMinimumEndDate(console: ConsoleEnum): Date | null {
    const activationDate = this.getConsoleActivationDate(console);
    if (activationDate === null) {
      return null;
    }
    return lastDayOfNextMonth(activationDate);
  }

  private setConsoleActivationDate(console: ConsoleEnum, date: Date | null): void {
    this.value.setConsoleActivationDate(console, date);
    this.emitNewValue();
  }

  private setConsoleEndDate(console: ConsoleEnum, date: Date | null): void {
    this.value.setConsoleEndDate(console, date);
    this.emitNewValue();
  }

  private isConsoleEnabled(console: ConsoleEnum): boolean {
    return this.value.consoles.includes(console);
  }

  private isToggleEnabled(console: ConsoleEnum): boolean {
    if (console === ConsoleEnum.SFC) {
      return this.value.consoles.includes(ConsoleEnum.BC);
    }
    return true;
  }

  private toggleConsole(console: ConsoleEnum) {
    if (this.value.consoles.includes(console)) {
      this.value.consoles = this.value.consoles.filter((x) => x !== console);
      this.setConsoleActivationDate(console, null);
      this.setConsoleEndDate(console, null);
      if (console === ConsoleEnum.BC && this.value.consoles.includes(ConsoleEnum.SFC)) {
        // Clean SFC values when BC is cleaned
        this.value.consoles = this.value.consoles.filter((x) => x !== ConsoleEnum.SFC);
        this.setConsoleActivationDate(ConsoleEnum.SFC, null);
        this.setConsoleEndDate(ConsoleEnum.SFC, null);
      }
    } else {
      this.value.consoles = [...this.value.consoles, console];
      this.setConsoleActivationDate(console, new Date());
      if ([ConsoleEnum.BC, ConsoleEnum.SFC].includes(console)) {
        this.setConsoleEndDate(console, moment().endOf('year').toDate());
      }
    }
    this.emitNewValue();
  }

  private emitNewValue() {
    this.$emit('input', this.value);
  }

  private get isValid() {
    return (
      (!this.value.consoles.includes(ConsoleEnum.WCC) ||
        (this.value.consoles.includes(ConsoleEnum.WCC) && !isNil(this.value.wccActivationDate))) &&
      (!this.value.consoles.includes(ConsoleEnum.BC) ||
        (this.value.consoles.includes(ConsoleEnum.BC) && !isNil(this.value.bcActivationDate))) &&
      (!this.value.consoles.includes(ConsoleEnum.SFC) ||
        (this.value.consoles.includes(ConsoleEnum.SFC) && !isNil(this.value.sfcActivationDate)))
    );
  }
}
