// --------------------------------------------------------------------------------
// <copyright file="tenants.service.ts" company="Bystronic Laser AG">
//  Copyright (C) Bystronic Laser AG 2021-2024
// </copyright>
// --------------------------------------------------------------------------------

import axios from 'axios';
import { BaseUrl } from '@/models/constants';
import { Tenant } from '@/models/tenant';
import { responseHandler } from './response.handler';
import { User } from '@/models/user';
import { Location } from '@/models/location';
import JSZip from 'jszip';
import store from '@/store';
import { PersistentActions, PersistentGetters } from '@/store/persistent/enums';
import { TenantReport } from '@/models/tenantReport';
import { TenantCutConfig } from '@/models/tenantCutConfig';
import { UserData, usersService } from '@/services/users.service';
import { BillingInfo } from '@/models/billingInfo';
import { downloadUrl } from '@/utils/misc';
import { CustomerEServiceData } from '@/views/careConsole/commons/model';

type TenantWithReportUsers = Tenant & { reportUserEmails?: string[] };

export type RestrictedTenant = {
  id: number;
  name: string;
};

class TenantsService {
  store = new (class {
    current(): Tenant | null {
      return store.getters[PersistentGetters.GetTenant];
    }

    currentIdDh(): number | undefined {
      return this.current()?.tenantIdDh;
    }

    async update(tenant: Tenant | null) {
      return await store.dispatch(PersistentActions.SetTenant, tenant);
    }
  })();

  async get(): Promise<Tenant[]> {
    const response = await axios.get<Tenant[]>(`${BaseUrl}/tenants`);
    responseHandler.handleDates(response.data);
    return response.data.map((tenant) => TenantsService.deserialize(tenant));
  }

  async getRestricted(): Promise<RestrictedTenant[]> {
    return await axios
      .get<RestrictedTenant[]>(`${BaseUrl}/tenants/restricted`)
      .then((response) => response.data);
  }

  async getReportUsers(tenantId: number): Promise<User[]> {
    const response = await axios.get<{ users: User[] }>(
      `${BaseUrl}/tenants/${tenantId}/report-users`,
    );
    return response.data.users;
  }

  async updateReport(tenantReport: TenantReport): Promise<void> {
    return axios.put(`${BaseUrl}/tenants/report`, tenantReport);
  }

  async updateCutConfig(tenantCutConfig: TenantCutConfig): Promise<void> {
    return axios.put(`${BaseUrl}/tenants/cut-config`, tenantCutConfig);
  }

  async getBillingInfo(excel: boolean, date: string): Promise<BillingInfo[]> {
    if (excel) {
      const response: any = await axios.get(`${BaseUrl}/tenants/control-list/${date}/excel`, {
        responseType: 'arraybuffer',
      });
      const url = window.URL.createObjectURL(
        new Blob([response.data], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        }),
      );
      downloadUrl(url, `ControlList.xlsx`);
      return response;
    } else {
      const response: any = await axios.get(`${BaseUrl}/tenants/control-list/${date}`);
      responseHandler.handleDates(response.data);
      return response.data;
    }
  }

  async getById(id: number | null): Promise<Tenant> {
    const response: any = await axios.get<Tenant>(`${BaseUrl}/tenants/${id}`);
    responseHandler.handleDates(response.data);
    return TenantsService.deserialize(response.data);
  }

  async getByIdDh(tenantIdDh: number | null): Promise<Tenant> {
    const response: any = await axios.get<Tenant>(`${BaseUrl}/tenants/dh/${tenantIdDh}`);
    responseHandler.handleDates(response.data);
    return TenantsService.deserialize(response.data);
  }

  async delete(id: number): Promise<Tenant[]> {
    const response: any = await axios.delete(`${BaseUrl}/tenants/${id}`);
    return response.data;
  }

  async update(tenant: TenantWithReportUsers): Promise<void> {
    return axios.put(`${BaseUrl}/tenants/`, tenant).then(() => {
      if (this.isCurrentTenant(tenant)) {
        store.dispatch(PersistentActions.SetTenant, tenant);
      }
    });
  }

  async create(tenant: Tenant) {
    const response: any = await axios.post(`${BaseUrl}/tenants/`, tenant);
    return response.data;
  }

  async getUsers(id: number): Promise<User[]> {
    const response = await axios.get<UserData[]>(`${BaseUrl}/tenants/${id}/users`);
    return response.data.map((user) => usersService.deserializeUser(user));
  }

  async getDatahubConfiguration(tenant: Tenant, location: Location) {
    const response: any = await axios.get(`${BaseUrl}/tenants/${tenant.id}/datahub/${location.id}`);

    const zip = new JSZip();
    zip.file('DataHub.config', response.data);
    const blob = await zip.generateAsync({ type: 'blob' });

    const link = document.createElement('a');
    link.setAttribute('href', window.URL.createObjectURL(blob));
    link.setAttribute(
      'download',
      `${tenant.tenantIdDh}-${location.name}-datahub-configuration.zip`,
    );
    document.body.appendChild(link);
    link.click();

    document.body.removeChild(link);
    return response;
  }

  async getBlobStorageSAS(tenantId: number) {
    const response: any = await axios.get(`${BaseUrl}/tenants/${tenantId}/blobstoragesas`);
    return response.data;
  }

  private isCurrentTenant(tenant: Tenant) {
    const currentTenant: Tenant = store.getters[PersistentGetters.GetTenant];
    return currentTenant.id === tenant.id;
  }

  private static deserialize(tenant: Tenant) {
    return Tenant.GetCopy(tenant);
  }

  async getEServiceCenterData(): Promise<CustomerEServiceData[]> {
    // /tenants/e-service-center
    const response = await axios.get<CustomerEServiceData[]>(`${BaseUrl}/tenants/e-service-center`);
    return response.data;
  }
}

const tenantsService = new TenantsService();
export { tenantsService, tenantsService as customersService };
