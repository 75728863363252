
import { Component, Prop, Vue } from 'vue-property-decorator';
import { UserType } from '@/models/userType';
import { Location } from '@/models/location';
import EditLocation from '@/components/locations/EditLocation.vue';
import { locationsService } from '@/services/locations.service';
import { Tenant } from '@/models/tenant';
import CreateLocation from '@/components/locations/CreateLocation.vue';
import TableActionButton from '@/components/tableWithActions/TableActionButton.vue';
import ConnectionStringModal from '@/components/locations/ConnectionStringModal.vue';
import TableWithActions from '@/components/tableWithActions/TableWithActions.vue';
import { canDownloadDataPlatform, hasCurrentUserPermission } from '@/utils/permissionUtils';
import { dataPlatformService } from '@/services/dataplatform.service';
import { downloadUrl } from '@/utils/misc';

@Component({
  methods: { canDownloadDataPlatform },
  components: {
    TableActionButton,
    TableWithActions,
  },
})
export default class CustomerLocations extends Vue {
  @Prop({ required: true, default: Tenant.GetEmpty() })
  private tenant!: Tenant;

  private locations: Location[] = [];
  private isPreparingDataPlatformDownload = false;

  private async created() {
    this.locations = await this.fetchLocations();
  }

  private async fetchLocations(): Promise<Location[]> {
    this.$spinner.showSpinner();
    return await locationsService
      .get(this.tenant.id)
      .finally(() => this.$spinner.removeSpinner());
  }

  private createLocation() {
    this.$buefy.modal.open({
      parent: this,
      component: CreateLocation,
      hasModalCard: true,
      trapFocus: true,
      props: {
        tenantId: this.tenant.id,
      },
      events: {
        close: async () => this.locations = await this.fetchLocations(),
      },
    });
  }

  private async downloadDataPlatform() {
    this.isPreparingDataPlatformDownload = true;
    const url = await dataPlatformService.getInstallerUrl()
      .finally(() => this.isPreparingDataPlatformDownload = false);
    downloadUrl(url);
  }

  private startEdit(location: Location) {
    this.$buefy.modal.open({
      parent: this,
      component: EditLocation,
      hasModalCard: true,
      trapFocus: true,
      props: {
        location,
      },
      events: {
        close: async () => this.locations = await this.fetchLocations(),
      },
    });
  }

  private confirmDelete(location: Location) {
    this.$buefy.dialog.confirm({
      title: this.$i18n.t('dialog.confirm_delete_title').toString(),
      message: location.hasApiKey
        ? this.$i18n
            .t('location.dialog.delete_connection_string', { name: location.name })
            .toString()
        : this.$i18n
            .t('confirmation.message', {
              action: this.$i18n.t('action.delete').toString().toLowerCase(),
              entity: this.$i18n.t('location.name').toString().toLowerCase(),
              name: location.name,
            })
            .toString(),
      confirmText: this.$i18n.t('dialog.confirm_delete').toString(),
      cancelText: this.$i18n.t('dialog.confirm_cancel').toString(),
      type: 'is-danger',
      hasIcon: true,
      onConfirm: () => this.deleteLocation(location.id),
    });
  }

  private deleteLocation(id: number) {
    this.$spinner.showSpinner();
    locationsService
      .delete(id)
      .then(() => this.locations = this.locations.filter((x) => x.id !== id))
      .finally(() => this.$spinner.removeSpinner());
  }

  private openConnectionStringDialog(location: Location) {
    this.$buefy.dialog.confirm({
      message: this.$t('location.dialog.confirm_connection_string').toString(),
      onConfirm: () => {
        this.$buefy.modal.open({
          parent: this,
          component: ConnectionStringModal,
          hasModalCard: true,
          trapFocus: true,
          canCancel: ['outside'],
          props: {
            location,
          },
          events: {
            close: async () => this.locations = await this.fetchLocations(),
          },
        });
      },
    });
  }

  private get canManageLocations(): boolean {
    return hasCurrentUserPermission(UserType.SubsidiaryAdmin);
  }
}
