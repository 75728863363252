
import { Component, Vue, Prop } from 'vue-property-decorator';
import { Device } from '@/models/device';
import { devicesService } from '@/services/devices.service';
import { DeviceModelType } from '@/models/deviceBaseModel';
@Component
export default class DevicesSelect extends Vue {
  @Prop({ default: '' })
  public value!: string;

  @Prop({ default: false })
  public required!: boolean;

  @Prop({ default: false })
  public disabled!: boolean;

  @Prop({ default: false })
  public includeAll!: boolean;

  @Prop({ default: false })
  public typeDevice!: DeviceModelType;

  public items: Device[] = [];

  public mounted(): void {
    this.getItems();
  }
  public async getItems() {
    this.$spinner.showSpinner();
    try {
      switch (this.typeDevice) {
        case DeviceModelType.Laser:
          this.items = await devicesService.store.cutDevices();
          break;
        case DeviceModelType.Bending:
          this.items = await devicesService.store.bendDevices();
          break;
        case DeviceModelType.Tubes:
          this.items = await devicesService.store.tubeDevices();
          break;
        default:
          this.items = await devicesService.get();
          break;
      }
      if (this.includeAll) {
        const allDevices = Device.GetEmpty();
        allDevices.name = 'All';
        this.items.unshift(allDevices);
      }
    } finally {
      this.$spinner.removeSpinner();
    }
  }

  public handleSelect(value: string) {
    this.$emit('input', value);
    this.$emit('change', value);
  }
}
