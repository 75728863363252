var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('section',[(_vm.createNewTargetButton)?_c('b-button',{staticClass:"action-button",attrs:{"icon-left":"plus"},on:{"click":function($event){return _vm.showCreateNewTarget()}}},[_vm._v(" "+_vm._s(_vm.$t('target.title_new'))+" ")]):_c('form',{ref:"form"},[_c('b-field',[_c('targets-metrics-select',{attrs:{"console":_vm.console,"required":true},model:{value:(_vm.target.targetMetric),callback:function ($$v) {_vm.$set(_vm.target, "targetMetric", $$v)},expression:"target.targetMetric"}})],1),_c('b-field',{staticClass:"required",attrs:{"message":!_vm.validInputs[0] ? _vm.$t('target.target_value.not_valid') : '',"label":_vm.target.targetMetric === _vm.TargetsMetric.LaserStarveBlock ||
        _vm.target.targetMetric === _vm.TargetsMetric.TubeStarveBlock
          ? _vm.$t('target.target_value.starve')
          : _vm.$t('target.target_value.label'),"type":{ 'is-danger': !_vm.validInputs[0] }}},[_c('b-input',{attrs:{"required":"","placeholder":"0"},on:{"input":function($event){return _vm.checkValidity($event, 0)}},model:{value:(_vm.targetValue1),callback:function ($$v) {_vm.targetValue1=$$v},expression:"targetValue1"}})],1),(_vm.numberOfTargetValues === 2)?_c('b-field',{staticClass:"required",attrs:{"message":!_vm.validInputs[1] ? _vm.$t('target.target_value.not_valid') : '',"label":_vm.target.targetMetric === _vm.TargetsMetric.LaserStarveBlock ||
        _vm.target.targetMetric === _vm.TargetsMetric.TubeStarveBlock
          ? _vm.$t('target.target_value.block')
          : _vm.$t('target.target_value.label'),"type":{ 'is-danger': !_vm.validInputs[1] }}},[_c('b-input',{attrs:{"required":"","placeholder":"0"},on:{"input":function($event){return _vm.checkValidity($event, 1)}},model:{value:(_vm.targetValue2),callback:function ($$v) {_vm.targetValue2=$$v},expression:"targetValue2"}})],1):_vm._e(),_c('div',{staticClass:"buttons"},[_c('b-button',{staticClass:"action-button",attrs:{"disabled":!_vm.isFormValid(),"icon-left":"content-save-outline"},on:{"click":function($event){return _vm.createNewTarget()}}},[_vm._v(" "+_vm._s(_vm.$t('target.title_create'))+" ")]),_c('b-button',{staticClass:"action-button",attrs:{"icon-left":"close"},on:{"click":function($event){return _vm.cancelNewTarget()}}},[_vm._v(" "+_vm._s(_vm.$t('action.cancel'))+" ")])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }