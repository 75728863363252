// --------------------------------------------------------------------------------
// <copyright file="bendingAvailabilityBarChartKpiWidgetGenerator.ts" company="Bystronic Laser AG">
//  Copyright (C) Bystronic Laser AG 2021-2024
// </copyright>
// --------------------------------------------------------------------------------

import { FilterTimeSpanEnum } from '../enums/FilterTimeSpanEnum';
import { BendingAvailabilityDataPerDevice } from './bendingAvailabilityKpiWidgetGenerator';
import { ProcedureName } from './abstract/chartGenerator';
import { metricsService } from '@/services/metrics.service';
import { devicesService } from '@/services/devices.service';
import { createPaletteByLightness } from '@/utils/color';
import { BarChartKpiWidgetGenerator } from './abstract/barChartKpiWidgetGenerator';

export class BendingAvailabilityBarChartKpiWidgetGenerator extends BarChartKpiWidgetGenerator<BendingAvailabilityDataPerDevice> {
  constructor(procedure: ProcedureName, tenantIdDh: number) {
    const mainColor = '#2750e4';
    const unit = 'h';
    super(procedure, tenantIdDh, mainColor, unit);
  }

  override async getData(
    selectedDevices: string[],
    selectedShifts: number[],
    timeSpan: FilterTimeSpanEnum | [string, string],
  ): Promise<BendingAvailabilityDataPerDevice[] | null> {
    return await metricsService.getDevicesMetrics<BendingAvailabilityDataPerDevice[]>(
      this.procedure,
      {
        tenantIdDh: this.tenantIdDh,
        deviceIds: selectedDevices,
        shifts: selectedShifts,
        timeSpan: timeSpan as FilterTimeSpanEnum,
      },
      this.controller,
    );
  }

  protected override getSeriesData(primaryData: BendingAvailabilityDataPerDevice[]): any[] {
    return primaryData.map((x) => ({
      name: devicesService.store.findByStringId(x.deviceId)!.name,
      value: x.bend,
    }));
  }

  protected override generatePalette(amount: number): string[] {
    return createPaletteByLightness(this.mainColor, amount, -0.45, 0.45);
  }
}
