// --------------------------------------------------------------------------------
// <copyright file="bendGanttChartTableGenerator.ts" company="Bystronic Laser AG">
//  Copyright (C) Bystronic Laser AG 2021-2024
// </copyright>
// --------------------------------------------------------------------------------

import { FilterTimeAxisSpanEnum } from '../../enums/FilterTimeAxisSpanEnum';
import { FilterTimeSpanEnum } from '../../enums/FilterTimeSpanEnum';
import { ProcedureName } from '../abstract/chartGenerator';
import { BEND_GANTT_PRODUCTION_MODES, BendGanttState, BendGanttTableData } from '../chartsData';
import { getBendStateColor } from '@/utils/color';
import { mockGanttTableData } from '../mockWidgetSelectorData';
import { AbstractGanttChartTableGenerator } from './abstractGanttChartTableGenerator';

export class BendGanttChartTableGenerator extends AbstractGanttChartTableGenerator<BendGanttState> {
  constructor(procedure: ProcedureName, private tenantIdDh: number) {
    super(procedure, BEND_GANTT_PRODUCTION_MODES);
  }

  override async getData(
    selectedDevices: string[],
    selectedShifts: number[],
    timeSpan: [string, string] | FilterTimeSpanEnum,
    timeAxisSpan?: FilterTimeAxisSpanEnum | undefined,
    params?: { [key: string]: any } | undefined,
    customerNo?: string | undefined,
  ): Promise<BendGanttTableData[] | null> {
    return this.getMockData();
  }

  protected override getStateColor(state: BendGanttState) {
    return getBendStateColor(state);
  }

  override getMockData(): BendGanttTableData[] | null {
    return mockGanttTableData(BEND_GANTT_PRODUCTION_MODES);
  }
}
