
import { WizardStep } from '@/components/wizard';
import { User } from '@/models/user';
import { UserType } from '@/models/userType';
import { Location } from '@/models/location';
import { locationsService } from '@/services/locations.service';
import { Component, Prop, Vue } from 'vue-property-decorator';
import { isEmpty } from '@/utils/misc';
import { ConsoleEnum } from '@/models/enums/ConsoleEnum';
import { usersService } from '@/services/users.service';

@Component({
  components: {
    WizardStep,
  },
  methods: {
    isEmpty,
  },
})
export default class PermissionsWizardStep extends Vue {
  @Prop({ required: true })
  private value!: User;

  @Prop({ default: false, type: Boolean })
  private hidden!: boolean;

  private customerLocations: Location[] = [];

  private availableUserTypes: UserType[] = [UserType.CustomerUser, UserType.CustomerAdmin];

  private async loadLocations() {
    if (!this.userHasEnabledWCC) {
      return;
    }
    this.$spinner.showSpinner();
    try {
      this.customerLocations = await locationsService.get(this.value.customerId);
    } finally {
      this.$spinner.removeSpinner();
    }
  }

  private onInput() {
    this.$emit('input', this.value);
  }

  private get userTypeIsNone() {
    return this.value.userType === UserType.None;
  }

  private get userHasEnabledWCC(): boolean {
    return this.value.consoles.includes(ConsoleEnum.WCC);
  }

  public get currentUser(): User {
    return usersService.store.current();
  }

  private get isEditingUser(): boolean {
    return this.value.id > 0;
  }

  private get userTypeDisabled(): boolean {
    return (
      this.isEditingUser && !(this.currentUser.isGlobalAdmin || this.currentUser.isSubsidiaryAdmin)
    );
  }
}
