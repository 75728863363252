
import { Component, Prop, Vue } from 'vue-property-decorator';
import { Device } from '@/models/device';
import { devicesService } from '@/services/devices.service';
import { Tenant } from '@/models/tenant';
import { Mode } from '@/store/enums';
import { UserType } from '@/models/userType';
import { PersistentActions } from '@/store/persistent/enums';
import { hasCurrentUserPermission } from '@/utils/permissionUtils';
import { locationsService } from '@/services/locations.service';
import { Location } from '@/models/location';
import DataHubConfigModal from '@/components/DataHubConfigModal.vue';
import MaintainDevice from '@/components/devices/MaintainDevice.vue';
import DeviceSASModel from '@/components/devices/DeviceSASModal.vue';
import TableActionButton from '@/components/tableWithActions/TableActionButton.vue';
import TableWithActions from '@/components/tableWithActions/TableWithActions.vue';
import { matchIgnoringCase } from '@/utils/table';
import { ShiftModel } from '@/models/shiftModel';
import { shiftModelsService } from '@/services/shiftModels.service';
import i18n from '@/i18n';
import moment from 'moment';

@Component({
  computed: {
    Mode: () => Mode,
  },
  components: {
    DataHubConfigModal,
    TableActionButton,
    TableWithActions,
  },
})
export default class Devices extends Vue {
  // This tenant will be undefined when the table is accessed directly
  // through the cog menu
  @Prop()
  private tenant?: Tenant;

  /*
  Temporarily removed. See #98032.
  @Ref('chooseConfig')
  private chooseDataHubConfig!: DataHubConfigModal;
  */

  private devices: Device[] = [];
  private locations: Location[] = [];
  private shiftModels: ShiftModel[] = [];

  private async created() {
    this.$spinner.showSpinner();

    try {
      this.shiftModels = await shiftModelsService.get(this.tenant?.id);
      this.locations = await locationsService.get(this.tenant?.id);
    } finally {
      this.$spinner.removeSpinner();
    }

    await this.updateDevices();
  }

  private async getWithStatuses(): Promise<Device[]> {
    const deviceStatuses = await devicesService.getDevicesStatus(this.tenant?.id);

    return this.devices.map((device) => {
      const deviceStatus = deviceStatuses.find((status) => device.deviceId === status.deviceId);

      if (deviceStatus) {
        device.status = deviceStatus;
      } else {
        device.setStatusToNotAvailable();
      }

      return device;
    });
  }

  private async openDeviceFormModal(mode: Mode, deviceToEdit?: Device) {
    this.$buefy.modal.open({
      parent: this,
      component: MaintainDevice,
      hasModalCard: true,
      trapFocus: true,
      events: {
        save: () => this.updateDevices(),
      },
      props: {
        mode,
        tenant: this.tenant,
        deviceIn: deviceToEdit,
      },
    });
  }

  private async updateDevices() {
    this.$spinner.showSpinner();

    try {
      this.devices = await devicesService.get(this.tenant?.id);
    } finally {
      this.$spinner.removeSpinner();
    }

    this.devices = await this.getWithStatuses();
  }

  private deleteDevice(deviceId: string): void {
    this.$spinner.showSpinner();
    devicesService
      .delete(deviceId)
      .then(() => {
        this.devices = this.devices.filter((x) => x.deviceId !== deviceId);
        this.$store.dispatch(PersistentActions.SetDevices, [...this.devices]);
      })
      .finally(() => {
        this.$spinner.removeSpinner();
      });
  }

  private confirmDelete(deviceId: string): void {
    this.$buefy.dialog.confirm({
      title: this.$i18n.t('dialog.confirm_delete_title').toString(),
      message: this.$i18n
        .t('confirmation.message', {
          action: this.$i18n.t('action.delete').toString().toLowerCase(),
          entity: this.$i18n.t('device.name').toString().toLowerCase(),
          name: deviceId,
        })
        .toString(),
      confirmText: this.$i18n.t('dialog.confirm_delete').toString(),
      cancelText: this.$i18n.t(`dialog.confirm_cancel`).toString(),
      type: 'is-danger',
      hasIcon: true,
      onConfirm: () => this.deleteDevice(deviceId), // TODO use id
    });
  }

  /*
  Temporarily removed. See #98032.
  private async confirmDownload() {
    if (this.locations.length === 1) {
      this.downloadDatahubConfiguration(this.locations[0]);
    } else {
      this.chooseDataHubConfig.openModal(this.resolvedTenant!.id);
    }
  }

  private selectLocation(locationId: number) {
    for (const location of this.locations) {
      if (location.id === locationId) {
        this.downloadDatahubConfiguration(location);
        break;
      }
    }
  }

  private downloadDatahubConfiguration(location: Location): void {
    this.$spinner.showSpinner();
    tenantsService
      .getDatahubConfiguration(this.resolvedTenant!, location)
      .then(() => {
        // ...
      })
      .finally(() => {
        this.$spinner.removeSpinner();
      });
  }
  */

  private async getSas() {
    this.$buefy.modal.open({
      parent: this,
      component: DeviceSASModel,
      hasModalCard: true,
      trapFocus: true,
      canCancel: ['outside'],
      props: {
        tenantId: this.tenant!.id,
      },
    });
  }

  private getLocationName(locationId: number): string {
    const location = this.locations.find((location) => location.id === locationId);

    return location!.name;
  }

  private getShiftModelName(shiftModelId?: number): string {
    const shiftModel = this.shiftModels.find((shiftModel) => shiftModel.id === shiftModelId);

    return shiftModel!.name;
  }

  private matchesLocation(device: Device, searchText: string): boolean {
    return matchIgnoringCase(this.getLocationName(device.locationId), searchText);
  }

  private matchesShiftModel(device: Device, searchText: string): boolean {
    if (!device.shiftModelId) {
      return matchIgnoringCase(i18n.t('shift_model.unassigned').toString(), searchText);
    }
    return matchIgnoringCase(this.getShiftModelName(device.shiftModelId), searchText);
  }

  private get canCreateAndDeleteEquipment(): boolean {
    return hasCurrentUserPermission(UserType.SubsidiaryAdmin);
  }

  private get canEditEquipment(): boolean {
    return this.canCreateAndDeleteEquipment || hasCurrentUserPermission(UserType.CustomerAdmin);
  }

  private get canGetSmbsSas(): boolean {
    return hasCurrentUserPermission(UserType.SubsidiaryAdmin);
  }

  private searchLastStatus(device: Device, filter: string) {
    return matchIgnoringCase(device.status ? device.status.lastSeenAgoText : '', filter);
  }

  private sortByLastStatus(a: Device, b: Device, isAsc: boolean) {
    if (a.status && a.status.lastSeen && b.status && b.status.lastSeen) {
      const dateA = moment(a.status.lastSeen);
      const dateB = moment(b.status.lastSeen);
      if (dateA.isBefore(dateB)) {
        return isAsc ? -1 : 1;
      } else if (dateA.isAfter(dateB)) {
        return isAsc ? 1 : -1;
      }
    }
    return 0;
  }

  private searchLastStatusText(device: Device, filter: string) {
    return matchIgnoringCase(device.status ? device.status.statusText : '', filter);
  }

  private sortByLastStatusText(a: Device, b: Device, isAsc: boolean) {
    const translatedTypeA: string = a.status ? a.status.statusText : '';
    const translatedTypeB: string = b.status ? b.status.statusText : '';
    return isAsc
      ? translatedTypeA.localeCompare(translatedTypeB)
      : translatedTypeB.localeCompare(translatedTypeA);
  }
}
