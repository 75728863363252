
import { Alert } from '@/models/alert';
import { AlertKPI } from '@/models/enums/AlertsEnums';
import { alertsService } from '@/services/alerts.service';
import { Component, Prop, Vue } from 'vue-property-decorator';
import { isEmpty } from '@/utils/misc';
import { translateUnit } from '@/utils/alerts';

@Component({
  methods: { isEmpty },
})
export default class EditCareAlert extends Vue {
  @Prop({ required: true })
  private alert!: Alert;

  private threshold: number | null = null;

  private alertKpi: AlertKPI | null = null;

  private async mounted() {
    this.threshold = this.alert.threshold;
    this.alertKpi = await alertsService.getAlertKpiByMetric(this.alert.metric);
  }

  private async submit() {
    const alertToSend = Alert.GetCopy(this.alert);
    alertToSend.threshold = this.threshold!;

    this.$spinner.showSpinner();

    try {
      await alertsService.update(alertToSend);
    } finally {
      this.$spinner.removeSpinner();
    }

    this.$emit('save');
    this.$emit('close');
  }

  private get hasFormChanged(): boolean {
    /* eslint-disable-next-line */
    return this.threshold != this.alert.threshold;
  }

  private get isFormValid(): boolean {
    return !isEmpty(this.threshold);
  }

  private get kpiUnit(): string | undefined {
    return translateUnit(this.alertKpi?.unit ?? null, null);
  }
}
