// --------------------------------------------------------------------------------
// <copyright file="environment.ts" company="Bystronic Laser AG">
//  Copyright (C) Bystronic Laser AG 2021-2024
// </copyright>
// --------------------------------------------------------------------------------

class Environment {
  getEnvironment(): EnvironmentValue {
    return process.env.VUE_APP_ENV?.toLowerCase() as EnvironmentValue
      ?? EnvironmentValue.DEVELOPMENT;
  }

  isDevelopmentEnvironment() {
    return this.getEnvironment() === EnvironmentValue.DEVELOPMENT;
  }

  getDataStorageBlobName() {
    return `kiotdatastorage${this.getEnvironment()}`;
  }
}

export default new Environment();


export enum EnvironmentValue {
  DEVELOPMENT = 'dev', // Used also for local
  QA = 'qa',
  PRODUCTION = 'pro',
}