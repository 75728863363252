
import { Component, Prop, Vue } from 'vue-property-decorator';
import { alertsService } from '@/services/alerts.service';
import { Tenant } from '@/models/tenant';
import { Alert } from '@/models/alert';
import CreateAlert from '@/components/alerts/CreateAlert.vue';
import EditAlert from '@/components/alerts/EditAlert.vue';
import { AlertOperator } from '@/models/enums/AlertsEnums';
import TableActionButton from '@/components/tableWithActions/TableActionButton.vue';
import TableWithActions from '@/components/tableWithActions/TableWithActions.vue';

@Component({
  components: {
    TableWithActions,
    TableActionButton,
  },
})
export default class CustomerAlerts extends Vue {
  @Prop({ required: true })
  private tenant!: Tenant;

  private alerts: Alert[] = [];

  private async created() {
    await this.getAlerts();
  }

  private async getAlerts() {
    this.$spinner.showSpinner();
    alertsService
      .getWorkCenterConsoleAlerts(this.tenant.id)
      .then((response) => (this.alerts = response))
      .finally(() => this.$spinner.removeSpinner());
  }

  private confirmDelete(alert: Alert): void {
    if (alert.id === null) {
      return;
    }

    this.$buefy.dialog.confirm({
      title: this.$i18n.t('dialog.confirm_title').toString(),
      message:
        this.$i18n.t('device.dialog.delete').toString() +
        ` <b>${alert.metric} ${alert.operator} ${alert.threshold} ${
          alert.deviceName ?? this.$i18n.t(`alert.all_devices`)
        }</b>?`,
      confirmText: this.$i18n.t('dialog.confirm_delete').toString(),
      cancelText: this.$i18n.t(`dialog.confirm_cancel`).toString(),
      type: 'is-danger',
      hasIcon: true,
      onConfirm: () => this.deleteAlert(Number(alert.id)),
    });
  }

  private async createAlert() {
    this.$buefy.modal.open({
      parent: this,
      component: CreateAlert,
      hasModalCard: true,
      trapFocus: true,
      events: {
        save: () => this.getAlerts(),
      },
      props: {
        tenant: this.tenant,
      },
    });
  }

  private async updateAlert(alert: Alert) {
    if (!!alert.id) {
      this.$buefy.modal.open({
        parent: this,
        component: EditAlert,
        hasModalCard: true,
        trapFocus: true,
        props: {
          alert,
          tenant: this.tenant,
        },
        events: {
          save: () => this.getAlerts(),
        },
      });
    }
  }

  private deleteAlert(alertId: number): void {
    this.$spinner.showSpinner();
    alertsService
      .delete(alertId)
      .then(() => {
        this.getAlerts();
      })
      .finally(() => {
        this.$spinner.removeSpinner();
      });
  }

  private getDeviceName(alert: Alert) {
    return alert.deviceName ?? this.$i18n.t(`alert.all_devices`);
  }

  private getMetric(alert: Alert) {
    return this.$i18n.t(`alert.metric.${alert.metric}`);
  }

  private getOperator(alert: Alert) {
    return this.$i18n.t(`alert_operators.${AlertOperator[alert.operator]}`);
  }

  private getAction(alert: Alert) {
    return this.$i18n.t(`alert.action.${alert.action}`);
  }
}
