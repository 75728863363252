
import { Component, Vue } from 'vue-property-decorator';
import { Subsidiary } from '@/models/subsidiary';
import { subsidiariesService } from '@/services/subsidiaries.service';
import { Routes } from '@/router/routes';
import CreateSubsidiary from '../../components/subsidiaries/CreateSubsidiary.vue';
import { UserType } from '@/models/userType';
import TableActionButton from '@/components/tableWithActions/TableActionButton.vue';
import TableWithActions from '@/components/tableWithActions/TableWithActions.vue';
import { usersService } from '@/services/users.service';
import { hasCurrentUserPermission } from '@/utils/permissionUtils';

@Component({
  components: {
    'sc-create-subsidiary': CreateSubsidiary,
    TableActionButton,
    TableWithActions,
  },
})
export default class Subsidiaries extends Vue {
  public subsidiaries: Subsidiary[] = [];
  public routes = Routes;
  public isEditMode = false;
  public editSubsidiary: Subsidiary = Subsidiary.GetEmpty();

  public confirmDelete(subsidiary: Subsidiary): void {
    this.$buefy.dialog.confirm({
      title: this.$i18n.t('dialog.confirm_delete_title').toString(),
      message: `${this.$i18n
        .t('confirmation.message', {
          action: this.$i18n.t('action.delete').toString().toLowerCase(),
          entity: this.$i18n.t('subsidiary.name').toString().toLowerCase(),
          name: subsidiary.name,
        })
        .toString()}
				${this.$i18n.t('subsidiary.msg_delete').toString()}`,
      confirmText: this.$i18n.t('dialog.confirm_delete').toString(),
      cancelText: this.$i18n.t(`dialog.confirm_cancel`).toString(),
      type: 'is-danger',
      hasIcon: true,
      onConfirm: () => this.deleteSubsidiary(subsidiary.id),
    });
  }

  public async created() {
    await this.getSubsidiaries();
  }

  public async startEdit(subsidiary: Subsidiary) {
    this.$router.push({ name: Routes.SubsidiaryDetail, params: { id: subsidiary.id.toString() } });
  }

  public async modalClose() {
    await this.getSubsidiaries();
  }

  private async getSubsidiaries() {
    this.$spinner.showSpinner();
    this.subsidiaries = await subsidiariesService
      .get()
      .finally(() => this.$spinner.removeSpinner());
  }

  private deleteSubsidiary(id: number): void {
    this.$spinner.showSpinner();
    subsidiariesService
      .delete(id)
      .then(() => {
        this.subsidiaries = this.subsidiaries.filter((x) => x.id !== id);
      })
      .finally(() => {
        this.$spinner.removeSpinner();
      });
  }

  public get canEditSubsidiaries(): boolean {
    return hasCurrentUserPermission(UserType.SubsidiaryAdmin);
  }

  get canCreateOrDeleteSubsidiaries(): boolean {
    return usersService.store.current().isGlobalAdmin;
  }
}
