// --------------------------------------------------------------------------------
// <copyright file="alerts.service.ts" company="Bystronic Laser AG">
//  Copyright (C) Bystronic Laser AG 2021-2024
// </copyright>
// --------------------------------------------------------------------------------

import axios from 'axios';
import { BaseUrl } from '@/models/constants';
import { Alert } from '@/models/alert';
import { AlertAction, AlertKPI, AlertKPIGroup, AlertMetric } from '@/models/enums/AlertsEnums';
import store from '@/store';
import { PersistentGetters } from '@/store/persistent/enums';
import { AlertsDefinition } from '@/models/alertsDefinition';

class AlertsService {
  getCareConsoleAlerts(): Promise<Alert[]> {
    return axios.get<Alert[]>(`${BaseUrl}/alerts/cc`).then((response: any) => {
      return response.data;
    });
  }

  getWorkCenterConsoleAlerts(tenantId: number): Promise<Alert[]> {
    return axios
      .get<Alert[]>(`${BaseUrl}/alerts/wcc?tenantId=${tenantId}`)
      .then((response: any) => {
        return response.data;
      });
  }

  delete(id: number): Promise<Alert[]> {
    return axios.delete(`${BaseUrl}/alerts/${id}`).then((response: any) => {
      return response.data;
    });
  }

  update(alert: Alert) {
    return axios.put(`${BaseUrl}/alerts/`, alert).then((response: any) => {
      return response.data;
    });
  }

  create(alert: Alert) {
    return axios.post(`${BaseUrl}/alerts/wcc`, alert).then((response: any) => {
      return response.data;
    });
  }

  createMultiple(alertsDefinition: AlertsDefinition) {
    return axios.post(`${BaseUrl}/alerts/cc`, alertsDefinition).then((response: any) => {
      return response.data;
    });
  }

  getAlertEmail(alertId: number): Promise<string[]> {
    return axios.get<string[]>(`${BaseUrl}/alerts/emailrecipients/${alertId}`).then((response) => {
      return response.data;
    });
  }

  async getAlertKPIs(): Promise<AlertKPIGroup[]> {
    const response = await axios.get<AlertKPIGroup[]>(`${BaseUrl}/alerts/kpis`);
    return response.data;
  }

  getAlertKpiByMetric(metric: AlertMetric | null): AlertKPI | null {
    const groups = store.getters[PersistentGetters.GetKPIGroups];
    return (
      groups
        .flatMap((group: AlertKPIGroup) => group.alertKPIs)
        .find((kpi: AlertKPI) => kpi.kpi === metric) ?? null
    );
  }

  getAlertMetricUnit(metric: AlertMetric): string | null {
    const groups = store.getters[PersistentGetters.GetKPIGroups];
    return groups
      .flatMap((group: AlertKPIGroup) => group.alertKPIs)
      .find((kpi: AlertKPI) => kpi.kpi === metric)!.unit;
  }

  subscribe(equipmentId: number): Promise<void> {
    return this.subscribeAll([equipmentId]);
  }

  unsubscribe(equipmentId: number): Promise<void> {
    return this.unsubscribeAll([equipmentId]);
  }

  async subscribeAll(selectedEquipmentIds: number[]): Promise<void> {
    return axios.put(`${BaseUrl}/alerts/equipment/subscribe`, {
      action: AlertAction.WebAndEmail,
      ids: selectedEquipmentIds,
    });
  }

  async unsubscribeAll(selectedEquipmentIds: number[]): Promise<void> {
    return axios.put(`${BaseUrl}/alerts/equipment/unsubscribe`, selectedEquipmentIds);
  }
}

export const alertsService = new AlertsService();
