
import { Component, Emit, Inject, Prop, Vue } from 'vue-property-decorator';

import Wizard from './Wizard.vue';

@Component({})
export default class WizardStep extends Vue {
  @Prop({ required: true })
  public title!: string;

  // For the default value to be a function, it needs to be returned by a function,
  //  because the default property also accepts functions
  @Prop({ required: false, default: () => () => true })
  public allowNextIf?: () => boolean;

  @Prop({ default: false })
  public hidden!: boolean;

  @Inject()
  private $wizard?: Wizard;

  private created() {
    this.$wizard?.refreshSteps();
  }

  private beforeDestroy() {
    this.$wizard?.refreshSteps();
  }

  @Emit('enter')
  public enter(fromPreviousPage: boolean) {
    return fromPreviousPage;
  }

  @Emit('leave')
  public leave() {
    return;
  }
}
