
import { Component, Prop, Vue } from 'vue-property-decorator';
import { MaterialGroup } from '@/models/materialGroup';
import { materialGroupsService } from '@/services/materialGroups.service';
import { Tenant } from '@/models/tenant';

@Component({})
export default class CreateMaterialGroup extends Vue {
  @Prop({ required: true, default: MaterialGroup.GetEmpty() })
  public tenant!: Tenant;
  public materialGroup: MaterialGroup = MaterialGroup.GetEmpty();
  public modalVisible = false;

  public handleChange(event: any): void {
    this.materialGroup = { ...this.materialGroup, [event.target.name]: event.target.value };
  }

  public openModal() {
    this.materialGroup = MaterialGroup.GetEmpty();
    this.modalVisible = true;
  }

  public get isEnabled() {
    if (this.materialGroup && this.$refs.form) {
      const refForm = this.$refs.form as any;
      return refForm.checkValidity();
    }
    return false;
  }

  public async onSubmit() {
    this.$spinner.showSpinner();

    this.materialGroup.tenantId = this.tenant.id;
    materialGroupsService
      .create(this.materialGroup)
      .then(() => {
        this.closeModal();
      })
      .finally(() => this.$spinner.removeSpinner());
  }

  public closeModal() {
    this.modalVisible = false;
    this.$emit('onClose');
  }
}
