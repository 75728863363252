// --------------------------------------------------------------------------------
// <copyright file="alertWorkflow.ts" company="Bystronic Laser AG">
//  Copyright (C) Bystronic Laser AG 2021-2024
// </copyright>
// --------------------------------------------------------------------------------
import { Tenant as Customer } from '@/models/tenant';
import { AlertOccurrence } from '@/models/alertOccurrence';
import { Device } from '@/models/device';
import { User } from '@/models/user';

/**
 * Information about an alert workflow created due to an alarm being triggered.
 *
 * Used to list alert workflows in the eService center's Inbox.
 */
export interface AlertWorkflow {
  id: number;
  occurrencesCount: number;
  createdDate: Date;
  assigneeName: User['name'] | null;
  status: AlertWorkflowStatus;
  processingDate: Date | null;

  customer: AlertWorkflowCustomer;
  equipment: AlertWorkflowEquipment;
  alertOccurrence: AlertWorkflowAlertOccurrence;
}

/** Customer with only the fields needed on AlertWorkflow. */
export type AlertWorkflowCustomer = Pick<Customer, 'tenantIdDh' | 'name' | 'timeZone'>;

/** Equipment with only the fields needed on AlertWorkflow. */
// XXX: We can't use Pick here because of the nullable fields, but names should
// match the Device's ones
export interface AlertWorkflowEquipment {
  deviceId: string;
  name: Device['name'];
  // This value comes from DataPlatform's flow were it might be null
  deviceBaseModelName: Device['deviceBaseModelName'] | null;
  // This value comes from DataPlatform's flow were it might be null
  equipmentId: Device['equipmentId'] | null;
}

/** AlertOccurrence with only the fields needed on AlertWorkflow. */
export type AlertWorkflowAlertOccurrence = Pick<
  AlertOccurrence,
  'metric' | 'variable' | 'eventSources' | 'eventCodes'
>;

export enum AlertWorkflowStatus {
  New = 1,
  Postponed = 2,
  InProgress = 3,
  Resolved = 4,
  Discarded = 5,
}

/**
 * AlertWorkflow with additional information.
 *
 * Used when a user wants to see additional information from an AlertWorkflow
 * listed in the Inbox.
 */
export interface AlertWorkflowDetail {
  id: AlertWorkflow['id'];
  occurrencesCount: AlertWorkflow['occurrencesCount'];
  createdDate: AlertWorkflow['createdDate'];
  assigneeName: AlertWorkflow['assigneeName'];
  status: AlertWorkflow['status'];
  processingDate: AlertWorkflow['processingDate'];
  postponedDate: Date | null;
  crmCaseNumber: string | null;
  resolutionDate: Date | null;
  closureInformation: string | null;
  lastOccurrenceDate: Date;

  customer: AlertWorkflow['customer'];
  equipment: AlertWorkflow['equipment'];
  alertOccurrence: AlertWorkflowDetailAlertOccurrence;
}

/** AlertOccurrence with only the fields needed on AlertWorkflowDetail. */
export type AlertWorkflowDetailAlertOccurrence = Pick<
  AlertOccurrence,
  | 'metric'
  | 'threshold'
  | 'dateGrouping'
  | 'latestValue'
  | 'operator'
  | 'eventSources'
  | 'eventCodes'
  | 'variable'
>;

/** AlertWorkflow data submitted when the user changes the status. */
export interface AlertWorkflowUpdateDTO {
  id: AlertWorkflow['id'];
  status: AlertWorkflow['status'];
  crmCaseNumber: AlertWorkflowDetail['crmCaseNumber'];
  postponedDate: AlertWorkflowDetail['postponedDate'] | null;
  closureInformation: AlertWorkflowDetail['closureInformation'];
}
